import { Navigate, useRoutes } from 'react-router-dom';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import Main from 'src/layouts/goodtimestheme/Main';
import { useEffect, useState } from 'react';
import { cateringData, millHouseData, websiteData } from 'src/WebsiteJSONExample';
import LandingPage from 'src/layouts/goodtimestheme/LandingPage';

// ----------------------------------------------------------------------

export default function Router() {
  const [website, setWebsite] = useState();
  const [millHouse, setMillHouse] = useState();
  const [cateringWebData, setCateringWebData] = useState();

  useEffect(() => {
    setWebsite(websiteData)
    setMillHouse(millHouseData)
    setCateringWebData(cateringData)
  }, [])

  let routes;

  var hostname = window.location.hostname;

  if (hostname === "goodtimes.beer" || hostname === "www.goodtimes.beer" || hostname === "localhost") {
    // Good Times Routes
    routes = [
      // Catering
      {
        path: '/catering/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={cateringWebData?.pages ? cateringWebData?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/catering/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...cateringData, path: '/catering' }} />
      },
      {
        path: '/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={website?.pages ? website?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={websiteData} />
      },
      {
        path: '/:pageId/:beerId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={websiteData} />
      },
    ]
  } else if (hostname === "millhousepizza.com" || hostname === "www.millhousepizza.com") {
    // Mill House Routes
    routes = [
      // Catering
      {
        path: '/catering/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={cateringWebData?.pages ? cateringWebData?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/catering/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...cateringData, path: '/catering' }} />
      },
      {
        path: '/drink',
        element: <Navigate to='/goodtimes' />

      },
      {
        path: '/goodtimes/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={website?.pages ? website?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/goodtimes/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...websiteData, path: '/goodtimes' }} />
      },
      {
        path: '/goodtimes/:pageId/:beerId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...websiteData, path: '/goodtimes' }} />
      },
      {
        path: '/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={millHouse?.pages ? millHouse?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={millHouseData} />
      },
    ]
  } else if (hostname === "goodtimes.catering" || hostname === "www.goodtimes.catering") {
    // Catering Routes
    routes = [
      {
        path: '/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={cateringWebData?.pages ? cateringWebData?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={cateringData} />
      },
    ]
  } else {
    routes = [

      // Auth routes
      // ...authRoutes,

      // Dashboard routes
      // ...dashboardRoutes,

      // Main routes
      // ...mainRoutes,

      // Landing Page
      {
        path: '/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <LandingPage />
      },
      // Catering
      {
        path: '/catering/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={cateringWebData?.pages ? cateringWebData?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/catering/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...cateringData, path: '/catering' }} />
      },

      // Good Times
      {
        path: '/goodtimes/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={website?.pages ? website?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/goodtimes/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...websiteData, path: '/goodtimes' }} />
      },
      {
        path: '/goodtimes/:pageId/:beerId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...websiteData, path: '/goodtimes' }} />
      },

      // Mill House
      {
        path: '/millhouse/',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Navigate to={millHouse?.pages ? millHouse?.pages?.items?.find(page => page.default === true)?.id : ''} replace />
      },
      {
        path: '/millhouse/:pageId',
        // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        element: <Main websiteData={{ ...millHouseData, path: '/millhouse' }} />
      },

      {
        path: '/food',
        element: <Navigate to='/millhouse' />
      },
      {
        path: '/drink',
        element: <Navigate to='/goodtimes' />

      },
      // No match 404
      { path: '*', element: <Navigate to="/" replace /> },
    ]
  }


  return useRoutes([...routes]);
}
