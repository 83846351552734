import PropTypes from 'prop-types';
import WebsiteContainer from '../utilities/Container';
import { Box, Button, Chip, Divider, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Mail, Phone } from '@mui/icons-material';
import { API } from 'aws-amplify';
import { LoadingButton } from '@mui/lab';

export default function ContactSection({ details, hours, index }) {
    const [loading, setLoading] = useState();
    const [messageSent, setMessageSent] = useState();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Remove any error when user starts typing
        if (formErrors[name]) {
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setMessageSent(null)

        // Basic validation
        const errors = {};

        if (!formData.name.trim()) errors.name = 'Name is required';
        if (!formData.email.trim()) errors.email = 'Email is required';
        if (!formData.message.trim()) errors.message = 'Message is required';

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        setLoading(true)

        const formDetails = {
            message: formData.message,
            phoneNumber: formData.phone,
            emailAddress: formData.email,
            sendTo: details.from || 'millhouse/goodtimes'
        }

        API.post('millhouseRestApi', '/contact', {
            body: {
                ...formDetails
            }
        }).then(response => {
            console.log(response)
            setLoading(false)
            setMessageSent({
                text: 'Message Sent!',
                color: 'success',
                success: true
            })
        }).catch(error => {
            console.log(error)
            setLoading(false)
            setMessageSent({
                text: 'Error sending message',
                color: 'error',
                success: false
            })
        })
    }

    const formatPhoneNumber = number => {
        const cleaned = ('' + number).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return number;
    }


    return (
        <>
            {details?.style === "simple" ?
                <WebsiteContainer index={index}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26402.415825141037!2d-82.16372600000001!3d34.18976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7ee51477d5e11e2!2sThe%20Mill%20House!5e0!3m2!1sen!2sus!4v1632334128213!5m2!1sen!2sus" width="100%" height="100%" style={{ border: '0', minHeight: '30em' }} allowfullscreen="" loading="lazy"></iframe>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={5} justifyContent='space-between'>
                                <Grid item xs={12}>
                                    <Typography variant='body1' fontSize={26}>
                                        Get in touch
                                    </Typography>
                                    <Divider sx={{ backgroundColor: 'black' }} />
                                    <Link href={details?.email ? `mailto:${details?.email}` : 'mailto:millhousegwd@gmail.com'} sx={{ textDecorationColor: theme => theme.palette.grey[600], textDecoration: 'none', textUnderlineOffset: 3, fontSize: 10, '&:hover': { textDecoration: 'underline', textDecorationColor: 'black' } }}>
                                        <Stack direction='row' my={0.5} alignItems='center' spacing={1} sx={{ color: theme => theme.palette.grey[700], '&:hover': { color: 'black' } }}>
                                            <Mail sx={{ fontSize: 18 }} />
                                            <Typography sx={{ fontSize: 16 }} >
                                                {details?.email || 'millhousegwd@gmail.com'}
                                            </Typography>
                                        </Stack>
                                    </Link>
                                    <Link href={details?.phone ? `tel:${details?.phone}` : 'tel:8643230321'} sx={{ textDecorationColor: theme => theme.palette.grey[600], textDecoration: 'none', textUnderlineOffset: 3, fontSize: 10, '&:hover': { textDecoration: 'underline', textDecorationColor: 'black' } }}>
                                        <Stack direction='row' my={0.5} alignItems='center' spacing={1} sx={{ color: theme => theme.palette.grey[700], '&:hover': { color: 'black' } }}>
                                            <Phone sx={{ fontSize: 18 }} />
                                            <Typography sx={{ fontSize: 16 }} >
                                                {formatPhoneNumber(details?.phone) || `(864) 323-0321`}
                                            </Typography>
                                        </Stack>
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={loading || (messageSent?.success === true)}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    label="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    helperText={formErrors.name}
                                                    error={Boolean(formErrors.name)}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={loading || (messageSent && messageSent?.success === true)}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    helperText={formErrors.email}
                                                    error={Boolean(formErrors.email)}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={loading || (messageSent && messageSent?.success === true)}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={loading || (messageSent && messageSent?.success === true)}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    label="Message"
                                                    name="message"
                                                    multiline
                                                    rows={5}
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    helperText={formErrors.message}
                                                    error={Boolean(formErrors.message)}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={3}>
                                                    {messageSent &&
                                                        <Chip sx={{ fontWeight: 800 }} color={messageSent.color || 'default'} label={messageSent.text || ''} />
                                                    }
                                                    <LoadingButton
                                                        loading={loading}
                                                        disabled={loading || (messageSent && messageSent?.success === true)}
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Submit
                                                    </LoadingButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </WebsiteContainer>
                :
                <WebsiteContainer index={index}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26402.415825141037!2d-82.16372600000001!3d34.18976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7ee51477d5e11e2!2sThe%20Mill%20House!5e0!3m2!1sen!2sus!4v1632334128213!5m2!1sen!2sus" width="100%" height="100%" style={{ border: '0', minHeight: '30em' }} allowfullscreen="" loading="lazy"></iframe>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={5} justifyContent='space-between'>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='body1' fontSize={26}>
                                        Get in touch
                                    </Typography>
                                    <Divider sx={{ backgroundColor: 'black' }} />
                                    <Link href={details?.email ? `mailto:${details?.email}` : 'mailto:millhousegwd@gmail.com'} sx={{ textDecorationColor: theme => theme.palette.grey[600], textDecoration: 'none', textUnderlineOffset: 3, fontSize: 10, '&:hover': { textDecoration: 'underline', textDecorationColor: 'black' } }}>
                                        <Stack direction='row' my={0.5} alignItems='center' spacing={1} sx={{ color: theme => theme.palette.grey[700], '&:hover': { color: 'black' } }}>
                                            <Mail sx={{ fontSize: 18 }} />
                                            <Typography sx={{ fontSize: 16 }} >
                                                {details?.email || 'millhousegwd@gmail.com'}
                                            </Typography>
                                        </Stack>
                                    </Link>
                                    <Link href={details?.phone ? `tel:${details?.phone}` : 'tel:8643230321'} sx={{ textDecorationColor: theme => theme.palette.grey[600], textDecoration: 'none', textUnderlineOffset: 3, fontSize: 10, '&:hover': { textDecoration: 'underline', textDecorationColor: 'black' } }}>
                                        <Stack direction='row' my={0.5} alignItems='center' spacing={1} sx={{ color: theme => theme.palette.grey[700], '&:hover': { color: 'black' } }}>
                                            <Phone sx={{ fontSize: 18 }} />
                                            <Typography sx={{ fontSize: 16 }} >
                                                {formatPhoneNumber(details?.phone) || `(864) 323-0321`}
                                            </Typography>
                                        </Stack>
                                    </Link>
                                </Grid>
                                {hours &&
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body1' fontSize={26}>
                                            Our Hours
                                        </Typography>
                                        <Divider sx={{ backgroundColor: 'black', mb: 0.5 }} />
                                        {hours?.map(hour =>
                                            <Typography variant='body1' fontSize={16}>

                                                <b>{hour.days}:</b> {hour.hours}
                                            </Typography>
                                        )}
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography fontSize={16} fontWeight={800} color='black' textAlign='center' mb={2}>
                                        We do not take reservations at this time.
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={loading || (messageSent?.success === true)}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    label="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    helperText={formErrors.name}
                                                    error={Boolean(formErrors.name)}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={loading || (messageSent && messageSent?.success === true)}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    helperText={formErrors.email}
                                                    error={Boolean(formErrors.email)}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={loading || (messageSent && messageSent?.success === true)}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={loading || (messageSent && messageSent?.success === true)}
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    label="Message"
                                                    name="message"
                                                    multiline
                                                    rows={5}
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    helperText={formErrors.message}
                                                    error={Boolean(formErrors.message)}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "darkgray",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={3}>
                                                    {messageSent &&
                                                        <Chip sx={{ fontWeight: 800 }} color={messageSent.color || 'default'} label={messageSent.text || ''} />
                                                    }
                                                    <LoadingButton
                                                        loading={loading}
                                                        disabled={loading || (messageSent && messageSent?.success === true)}
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Submit
                                                    </LoadingButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </WebsiteContainer>
            }
        </>
    )
}

ContactSection.propTypes = {
    hours: PropTypes.array,
    index: PropTypes.number,
    details: PropTypes.object
}