import PropTypes from 'prop-types';
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext } from 'react';
import WebsiteData from '../contexts/WebsiteData';
import { useNavigate } from 'react-router';

Banner.propTypes = {
    details: PropTypes.object,
    index: PropTypes.number
}

export default function Banner({ details, index }) {
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const { hours, title } = useContext(WebsiteData)
    const navigate = useNavigate();

    const handleButtonClick = (path, type) => {
        if (type === 'external') {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    console.log(hours)
    return (
        <Container
            maxWidth
            sx={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${details.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '550px',
                display: 'flex',
                py: 4
            }}
        >
            <Stack justifyContent='center' sx={{ mx: 'auto' }} spacing={4}>
                <Typography variant='h3' fontWeight={800} textAlign='center'>
                    { details.title || title || 'Good Times Brewing'}
                    {console.log(details)}
                </Typography>
                {details.subtext ?
                    <Typography variant='h5' textAlign={'center'}>
                        {details.subtext}
                    </Typography>
                    :
                    <Stack direction={isMd ? 'row' : 'column'} spacing={3}>
                        {
                            hours?.map(hourSet =>
                                <Typography variant='h5' textAlign={isMd ? 'left' : 'center'}>
                                    <b>{hourSet.days}</b>: {hourSet.hours}
                                </Typography>
                            )
                        }
                    </Stack>
                }

                {!details.noButtons &&
                    <>
                        {details.buttons ?
                            <Stack direction='row' justifyContent='center' spacing={5}>
                                {details.buttons.map(button =>
                                    <Button variant='contained' onClick={() => { handleButtonClick(button.path, button.type)}} color='primary' size={isMd ? 'large' : 'small'}>
                                        {button.title}
                                    </Button>
                                )}
                            </Stack>
                            :
                            <Stack direction='row' justifyContent='center' spacing={5}>
                                <Button variant='contained' color='primary' size={isMd ? 'large' : 'small'}>
                                    Order Online
                                </Button>
                                <Button variant='contained' color='primary' size={isMd ? 'large' : 'small'}>
                                    Catering
                                </Button>
                            </Stack>
                        }
                    </>
                }
            </Stack>
        </Container >
    )
}