import PropTypes from 'prop-types';
import { AppBar, Box, Button, Collapse, Container, Divider, Drawer, Grid, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography, useMediaQuery, useScrollTrigger } from "@mui/material";
import { useNavigate, useParams } from 'react-router';
import { cloneElement, useContext, useState } from 'react';
import { ArrowDropDown, ExpandLess, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import Logo from '../Logo';
import WebsiteData from '../contexts/WebsiteData';

function Header({ pages, topLinks, path = '' }) {
    const { pageId } = useParams()
    const navigate = useNavigate()
    const [menuPages, setMenuPages] = useState();
    const [menuAnchor, setMenuAnchor] = useState();
    const [menuExpanded, setMenuExpanded] = useState();
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const [drawerOpen, setDrawerOpen] = useState(false);
    const sortedPages = pages?.sort((a, b) => a.index - b.index)
    const { logo } = useContext(WebsiteData)

    function ElevationScroll({ children, window }) {
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window() : undefined,
        });

        return cloneElement(children, {
            elevation: trigger ? 4 : 0,
        });
    }

    ElevationScroll.propTypes = {
        children: PropTypes.element.isRequired,
        /**
         * Injected by the documentation to work in an iframe.
         * You won't need it on your project.
         */
        window: PropTypes.func,
    };

    const handleMenuOpen = (event, menuItems) => {
        setMenuPages(menuItems)
        setMenuAnchor(event.currentTarget)
    }

    const handleNavigation = (linkObj) => {
        if (linkObj.type === 'external') {
            console.log(linkObj.url)
            window.open(linkObj.url, '_BLANK')
        } else {
            navigate(`${path}/${linkObj.id.toLocaleLowerCase()}`)
        }
    }

    return (
        <>
            {isMd ?
                <Container
                    maxWidth
                    sx={{ backgroundColor: theme => theme.palette.common.black }}
                >
                    <Container
                        sx={{
                            p: 2,
                            // borderBottomWidth: 1,
                            // borderBottomStyle: 'solid',
                            // BorderColor: (theme) => theme.palette.divider
                        }}
                        disableGutters
                        maxWidth='lg'
                    >
                        <Grid container justifyContent='space-between' alignItems='center' py={1}>
                            <Grid item>
                                <Logo src={logo} onClick={() => { navigate(`${path}/`) }} sx={{ maxHeight: 75, minHeight: 75, width: 'auto', cursor: 'pointer' }} />
                            </Grid>
                            <Grid item>
                                <Stack justifyContent='right' spacing={3}>
                                    <Stack direction='row' spacing={4}>
                                        {sortedPages?.map(page => (
                                            <>
                                                {page.parent === true ?
                                                    <Link
                                                        onClick={(event) => {
                                                            handleMenuOpen(event, page.children)
                                                        }}
                                                        sx={{
                                                            color: 'inherit',        // Makes the link use the current text color
                                                            textDecoration: 'none',  // Removes the underline
                                                            '&:hover': {             // Styles for hover state
                                                                textDecoration: 'none',
                                                                color: 'inherit'
                                                            }
                                                        }}
                                                    >
                                                        <Stack
                                                            direction='row'
                                                            alignItems='center'
                                                        >

                                                            <Typography
                                                                variant='body1'
                                                                sx={{ ...((page.children?.some(child => child.id?.toLocaleLowerCase() === pageId?.toLocaleLowerCase())) && { textDecoration: 'underline', textUnderlineOffset: 10 }), ":hover": { textDecoration: 'underline', textUnderlineOffset: 10, cursor: 'pointer' } }}
                                                            >
                                                                {page.title}
                                                            </Typography>
                                                            <ArrowDropDown />
                                                        </Stack>
                                                    </Link>
                                                    :
                                                    <Typography onClick={() => { handleNavigation(page) }} variant='body1' sx={{ ...((pageId?.toLocaleLowerCase() === page.id.toLocaleLowerCase()) && { textDecoration: 'underline', textUnderlineOffset: 10 }), ":hover": { textDecoration: 'underline', textUnderlineOffset: 10, cursor: 'pointer' } }}>
                                                        {page.title}
                                                    </Typography>
                                                }
                                            </>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuPages)}
                            onClose={() => {
                                setMenuPages(null)
                            }}
                            disableAutoFocusItem
                        >
                            {
                                menuPages?.map(page =>
                                    <MenuItem onClick={() => { navigate(`${path}/${page.id.toLocaleLowerCase()}`); setMenuPages(null); setMenuAnchor(null); }} selected={(pageId?.toLocaleLowerCase() === page.id.toLocaleLowerCase())}>
                                        {page.title}
                                    </MenuItem>
                                )
                            }
                            {/* <MenuItem onClick={() => { }}>Profile</MenuItem>
                        <MenuItem onClick={() => { }}>My account</MenuItem>
                        <MenuItem onClick={() => { }}>Logout</MenuItem> */}
                        </Menu>
                    </Container>
                </Container>
                :
                <>
                    <ElevationScroll>
                        <AppBar
                            sx={{
                                backgroundImage: 'unset',
                                backgroundColor: theme => theme.palette.common.black,
                                '&.MuiAppBar-elevated': {
                                    backgroundColor: theme => theme.palette.common.black,
                                },
                            }}
                        >
                            <Toolbar>
                                <Logo src={logo} onClick={() => { navigate(`${path}/`) }} sx={{ maxHeight: 40, minHeight: 40, width: 'auto', cursor: 'pointer' }} />
                                <IconButton onClick={() => { setDrawerOpen(true) }} sx={{ marginLeft: 'auto' }}>
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar />
                    <Drawer
                        anchor='left'
                        open={drawerOpen}
                        onClose={() => {
                            setDrawerOpen(false)
                        }}
                        PaperProps={{
                            sx: { backgroundColor: theme => theme.palette.common.black }
                        }}
                    >
                        <Box sx={{ width: 250, pt: 2 }}>
                            <center>
                                <Logo src={logo} onClick={() => { navigate(`${path}/`); setDrawerOpen(false); }} sx={{ maxHeight: 70, minHeight: 60, width: 'auto', maxWidth: 200, cursor: 'pointer' }} />
                            </center>
                        </Box>
                        <Divider sx={{ py: 1 }} />
                        {/* <Stack spacing={2} px={1.5}>
                            {topLinks?.sort((a, b) => a.index > b.index)?.map(link =>
                                <Button
                                    fullWidth
                                    color='secondary'
                                    onClick={() => {
                                        if (link.type === 'external') {
                                            window.open(link.path, '_blank');
                                        } else {
                                            navigate(link.path);
                                        }
                                    }}
                                    variant='outlined'
                                    size='small'
                                >
                                    {link.title}
                                </Button>
                            )}
                        </Stack>
                        <Divider sx={{ py: 1 }} /> */}
                        <Box sx={{ width: 250 }}>

                            {sortedPages?.map((page, index) => (
                                <>
                                    {page.parent === true ?
                                        <>
                                            <ListItemButton selected={(page.children?.some(child => child.id?.toLocaleLowerCase() === pageId?.toLocaleLowerCase()))} onClick={() => (menuExpanded === index) ? setMenuExpanded(null) : setMenuExpanded(index)}>
                                                <ListItemText primary={page.title} />
                                                {menuExpanded === index ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={menuExpanded === index} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        page.children.map(childPage =>
                                                            <ListItemButton selected={(pageId?.toLocaleLowerCase() === childPage.id.toLocaleLowerCase())} onClick={() => { setDrawerOpen(false); navigate(`${path}/${childPage.id.toLocaleLowerCase()}`); }} sx={{ pl: 3 }}>
                                                                <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                                                    •
                                                                </ListItemIcon>
                                                                <ListItemText primary={childPage.title} />
                                                            </ListItemButton>
                                                        )
                                                    }
                                                </List>
                                            </Collapse>
                                        </>
                                        :
                                        <ListItemButton selected={(pageId?.toLocaleLowerCase() === page.id.toLocaleLowerCase())} onClick={() => { setDrawerOpen(false); handleNavigation(page); }}>
                                            <ListItemText primary={page.title} />
                                        </ListItemButton>
                                    }
                                </>
                            ))}

                        </Box>
                    </Drawer>
                </>
            }
        </>
    );
}

Header.propTypes = {
    pages: PropTypes.array.isRequired,
    topLinks: PropTypes.array,
    path: PropTypes.string
};

export default Header;
