import PropTypes from 'prop-types';
import { Box, Collapse, Container, Fab, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore, Facebook, FacebookOutlined, Google, Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import { websiteData } from "src/WebsiteJSONExample";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import Logo from "../Logo";
import WebsiteData from '../contexts/WebsiteData';


export default function Footer({ pages = [], path = '' }) {
    const navigate = useNavigate();
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const [menuExpanded, setMenuExpanded] = useState();
    const { logo, title } = useContext(WebsiteData);
    const currentYear = new Date().getFullYear();
    const sortedPages = pages?.sort((a, b) => a.index - b.index)

    const handleNavigation = (linkObj) => {
        if (linkObj.type === 'external') {
            window.open(linkObj.url, '_BLANK')
        } else {
            navigate(`${path}/${linkObj.id.toLocaleLowerCase()}`)
        }
    }

    return (
        <>
            <Container maxWidth sx={{ backgroundColor: theme => theme.palette.common.white, color: theme => theme.palette.common.black, py: 8 }}>
                <Stack direction='row' spacing={2} justifyContent='center' py={3}>
                    {websiteData?.socials?.facebook &&
                        <IconButton color='white' size='large' onClick={() => { window.open(websiteData?.socials?.facebook, '_blank') }}>
                            <FacebookOutlined />
                        </IconButton>
                    }
                    {websiteData?.socials?.google &&
                        <IconButton color='white' size='large' onClick={() => { window.open(websiteData?.socials?.google, '_blank') }}>
                            <Google />
                        </IconButton>
                    }
                </Stack>
                <Typography variant='h5' fontSize={28} textAlign='center'>
                    237 MAXWELL AVE GREENWOOD, SC 29646 &#x2022; 864-323-0321
                </Typography>
            </Container>
            <Container maxWidth sx={{ backgroundColor: theme => theme.palette.common.black }} >
                <Container maxWidth='md' sx={{ py: 3 }}>
                    <Stack direction={isMd ? 'row' : 'column'} justifyContent='space-between' alignItems='center' spacing={3}>
                        {isMd &&
                            <Box>
                                {sortedPages?.map((page, index) => (
                                    <>
                                        {page.parent ?
                                            <>
                                                <Stack direction='row'>
                                                    <Typography onClick={() => (menuExpanded === index) ? setMenuExpanded(null) : setMenuExpanded(index)} fontWeight={600} fontSize={15} variant="body1" paddingLeft={3} sx={{ ":hover": { textDecoration: 'underline', textUnderlineOffset: 5, cursor: 'pointer' } }}>
                                                        {page.title}
                                                    </Typography>
                                                    {menuExpanded === index ? <ExpandLess onClick={() => (menuExpanded === index) ? setMenuExpanded(null) : setMenuExpanded(index)} sx={{ cursor: 'pointer' }} /> : <ExpandMore onClick={() => (menuExpanded === index) ? setMenuExpanded(null) : setMenuExpanded(index)} sx={{ cursor: 'pointer' }} />}
                                                </Stack>

                                                <Collapse in={menuExpanded === index} timeout="auto" unmountOnExit>
                                                    {
                                                        page.children.map(childPage =>
                                                            <Typography onClick={() => {handleNavigation(childPage) }} ml={3} fontWeight={600} fontSize={15} variant="body1" paddingLeft={3} sx={{ ":hover": { textDecoration: 'underline', textUnderlineOffset: 5, cursor: 'pointer' } }}>
                                                                {childPage.title}
                                                            </Typography>
                                                        )
                                                    }
                                                </Collapse>
                                            </>
                                            :
                                            <Typography onClick={() => { handleNavigation(page) }} fontWeight={600} fontSize={15} variant="body1" paddingLeft={3} sx={{ ":hover": { textDecoration: 'underline', textUnderlineOffset: 5, cursor: 'pointer' } }}>
                                                {page.title}
                                            </Typography>
                                        }
                                    </>
                                ))}
                            </Box>
                        }
                        <Box>
                            <Logo src={logo} sx={{ minHeight: 50, maxHeight: 100 }} />
                            <Stack direction='row' justifyContent='center' spacing={2} mt={3}>
                                {websiteData?.socials?.facebook &&
                                    <IconButton color='white' size='medium' onClick={() => { window.open(websiteData?.socials?.facebook, '_blank') }}>
                                        <Facebook />
                                    </IconButton>
                                }
                                {websiteData?.socials?.twitter &&
                                    <IconButton color='white' size='medium' onClick={() => { window.open(websiteData?.socials?.twitter, '_blank') }}>
                                        <Twitter />
                                    </IconButton>
                                }
                                {websiteData?.socials?.youtube &&
                                    <IconButton color='white' size='medium' onClick={() => { window.open(websiteData?.socials?.youtube, '_blank') }}>
                                        <YouTube />
                                    </IconButton>
                                }
                                {websiteData?.socials?.instagram &&
                                    <IconButton color='white' size='medium' onClick={() => { window.open(websiteData?.socials?.linkedin, '_blank') }}>
                                        <Instagram />
                                    </IconButton>
                                }
                                {websiteData?.socials?.google &&
                                    <IconButton color='white' size='medium' onClick={() => { window.open(websiteData?.socials?.google, '_blank') }}>
                                        <Google />
                                    </IconButton>
                                }
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
                <Typography variant="body2" py={2} textAlign='center'>
                    © Copyright {currentYear} | {title}. All Rights Reserved
                </Typography>
            </Container>
        </>
    )
}

Footer.propTypes = {
    pages: PropTypes.array.isRequired
};