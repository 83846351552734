import PropTypes from 'prop-types';
import Banner from './components/Banner';
import Events from './components/Events';
import TextArea from './components/TextArea';
import ImageGallery from './components/ImageGallery';
import StaffSection from './components/StaffSection';
import ContactSection from './components/Contact';
import { useContext } from 'react';
import WebsiteData from './contexts/WebsiteData';
import BeerCards from './components/BeerCards';
import Menu from './components/Menu';

export default function RenderComponent({component, index}){
    const websiteData = useContext(WebsiteData);
    let websiteComponent;

    console.log(component)

    switch(component.type) {
        case 'banner':
            websiteComponent = <Banner index={index} details={component} />
        break;
        case 'events':
            websiteComponent = <Events index={index} details={component} />
        break;
        case 'text':
            websiteComponent = <TextArea index={index} details={component} />
        break;
        case 'imageGallery':
            websiteComponent = <ImageGallery index={index} details={component} />
        break;
        case 'staff':
            websiteComponent = <StaffSection index={index} details={component} />
        break;
        case 'contact': 
            websiteComponent = <ContactSection details={component} index={index} hours={websiteData?.hours} />
        break;
        case 'beerCards':
            websiteComponent = <BeerCards index={index} beers={websiteData?.beers} />
        break;
        case 'menu':
            websiteComponent = <Menu index={index} details={component} />
        break;
        default:
            websiteComponent = <></>
    }

    return (
        <>
            {websiteComponent}
        </>
    )
}

RenderComponent.propTypes = {
    component: PropTypes.object,
    index: PropTypes.number
}