import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import {  Stack } from '@mui/material';

function BeerCan({ label }) {
    const [model, setModel] = useState(null);
    const labelTexture = useTexture(label);
    const diffuseTexture = useTexture('/3dmodels/diffuse.jpeg');
    const metalnessTexture = useTexture('/3dmodels/aluminum.jpeg');
    // const roughnessTexture = useTexture('/3dmodels/rough.jpeg');

    useEffect(() => {
        const objLoader = new OBJLoader();
        objLoader.load('/3dmodels/can.obj', (loadedModel) => {
            loadedModel.position.set(0, -0.08, 0);
            loadedModel.traverse((child) => {
                if (child.isMesh) {
                    let materialConfig = {
                        metalnessMap: metalnessTexture,
                        // roughnessMap: roughnessTexture,
                        metalness: 1,
                    };
                    // materialConfig.map = diffuseTexture;
                    child.material = new THREE.MeshStandardMaterial({
                        ...materialConfig,
                        color: new THREE.Color(3, 3, 3) // This makes the material color slightly brighter
                    });
                    child.castShadow = true
                    child.receiveShadow = true
                }
            });
            setModel(loadedModel);
        });
    }, [diffuseTexture, labelTexture, metalnessTexture]);

    const meshRef = useRef();
    const [isUserInteracting] = useState(false);

    useFrame(() => {
        if (!isUserInteracting && meshRef.current) {
            meshRef.current.rotation.y += 0.01;
        }
    });

    return (
        model ? (
            <group ref={meshRef} scale={[20, 16, 20]}>
                <primitive object={model} />
                <mesh position={[0, -0.005, 0]} scale={[0.0333, 0.035, 0.0333]}>
                    <cylinderGeometry args={[1, 1, 3.2, 32]} />
                    <meshStandardMaterial map={labelTexture} side={THREE.DoubleSide} />
                </mesh>
            </group>
        ) : null
    );
}

BeerCan.propTypes = {
    label: PropTypes.string
}

function RenderedBeerCan({ label, height = false }) {
    const [zoomFactor, setZoomFactor] = useState(175); // Set initial value
    const [canvasDimensions, setCanvasDimensions] = useState({ width: '100%', height: '100%' });

    const containerRef = useRef(null);

    useEffect(() => {
        const adjustCanvasSize = () => {
            if (containerRef.current) {
                setCanvasDimensions({
                    width: `${containerRef.current.offsetWidth}px`,
                    height: `${containerRef.current.offsetHeight}px`
                });
            }
        };

        // Initial adjustment
        adjustCanvasSize();

        // Listen for window resize to adjust canvas size
        window.addEventListener('resize', adjustCanvasSize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', adjustCanvasSize);
        };
    }, []);


    useEffect(() => {
        const adjustZoom = () => {
            if (containerRef.current) {
                const containerHeight = containerRef.current.offsetHeight;
                const newZoom = height ? height / 4 : 12 * (500 / containerHeight);
                setZoomFactor(newZoom);
            }
        };

        // Initial adjustment
        adjustZoom();

        // Setup ResizeObserver to watch for changes in size
        const resizeObserver = new ResizeObserver(adjustZoom);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Cleanup
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <Stack justifyContent='center' ref={containerRef} style={{ overflow: 'hidden', maxWidth: '100%', height: height || null }}>
            <Canvas
                style={{ maxWidth: '96vw' }}
                shadows={true}
                orthographic={true}
                camera={{ position: [0, 1, 5], zoom: zoomFactor }}
                resize={{ browser: true }}
            >
                <ambientLight intensity={1.3} />
                <pointLight
                    distance={0}
                    position={[25, 8, 8]}
                    intensity={1000}
                    castShadow
                    shadow-mapSize-width={500}
                    shadow-mapSize-height={500}
                    shadow-radius={40}
                />
                {/* <OrbitControls
                    enableZoom={false}
                    enablePan={false}
                    enableRotate={false}
                    target={[0, 0, 0]}
                /> */}
                <BeerCan label={label} />
            </Canvas>
        </Stack>
    );
}




RenderedBeerCan.propTypes = {
    label: PropTypes.string
}

export default RenderedBeerCan;
