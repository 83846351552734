import { styled } from '@mui/material';
import PropTypes from 'prop-types';


const StyledLogo = styled('img')``;

export default function Logo({ src, alt, sx, ...props }) {
    return (
        <>
            {src &&
                <StyledLogo {...props} src={src} alt={alt} sx={{ ...sx }} />
            }
        </>
    )
}

Logo.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    sx: PropTypes.object
};