import PropTypes from 'prop-types';
import WebsiteContainer from '../utilities/Container';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, Link, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export default function Menu({ details, index }) {

    return (
        <WebsiteContainer index={index}>
            <Typography
                variant='h4'
                fontWeight={800}
                textAlign='left'
                sx={{
                    textDecoration: 'underline',
                    textDecorationThickness: 1,
                    textDecorationColor: theme => theme.palette.grey[400],
                    textDecorationStyle: 'solid',
                    textUnderlineOffset: 13,
                    mb: 4
                }}
            >
                {details.title}
            </Typography>
            {details.categories?.map(category =>
                <Accordion sx={{ my: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Stack>
                            <Typography fontSize={26} fontWeight={800}>
                                {category.title}
                            </Typography>
                            <Typography variant='body2' sx={{ color: 'text.secondary' }}>{category.subtitle}</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        {category.items.map((item, index) =>
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start" mb={(index === category.items.length - 1) ? 0 : 3}>
                                <Box>
                                    <Link href={details.orderLink} target='_BLANK' sx={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}>
                                        <Typography variant='body1' fontSize={20} fontWeight={800}>
                                            {item.title} {item.chip && <Chip color='error' size='small' label={item.chip} />}
                                        </Typography>
                                        <Typography variant='body1' fontWeight={500}>
                                            {item.description}
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography variant='body1' fontWeight={500} fontSize={20} textAlign='right'>
                                    {item.price ? `$${item.price}` : ''}
                                </Typography>
                            </Stack>
                        )}
                    </AccordionDetails>
                </Accordion>
            )}
        </WebsiteContainer>
    )
}

Menu.propTypes = {
    details: PropTypes.object,
    index: PropTypes.number
}