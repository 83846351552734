import PropTypes from 'prop-types';
import WebsiteContainer from '../utilities/Container';
import { Divider, Grid, Typography, styled, useMediaQuery } from '@mui/material';

const StyledImg = styled('img')``

TextArea.propTypes = {
    details: PropTypes.object,
    index: PropTypes.number
}

export default function TextArea({ details, index }) {
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))

    return (
        <WebsiteContainer index={index}>
            <>
                <Typography
                    variant='h4'
                    fontWeight={800}
                    textAlign={isMd ? 'left' : 'center'}
                    sx={{
                        textDecoration: 'underline',
                        textDecorationThickness: 1,
                        textDecorationColor: theme => theme.palette.grey[400],
                        textDecorationStyle: 'solid',
                        textUnderlineOffset: 13,
                        mb: 4
                    }}
                >
                    {details.title}
                </Typography>
                {details?.image ?
                    <>
                        {details?.imageRight ?
                            <Grid container spacing={4}>
                                <Grid item xs={12} alignSelf='center' md={7} order={isMd ? 1 : 2}>
                                    <Typography variant='body1' fontSize={18}>
                                        <div dangerouslySetInnerHTML={{ __html: details.content }} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} alignSelf='stretch' md={5} order={isMd ? 2 : 1}>
                                    <StyledImg
                                        sx={{
                                            objectFit: 'cover', // Adjust this to 'contain' if needed
                                            objectPosition: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        src={details.image}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container alignItems='center' spacing={4}>
                                <Grid item xs={12} md={5}>
                                    <StyledImg
                                        sx={{
                                            objectFit: 'cover', // Adjust this to 'contain' if needed
                                            objectPosition: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        src={details.image}
                                    />
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Typography variant='body1' fontSize={18}>
                                        <div dangerouslySetInnerHTML={{ __html: details.content }} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    </>
                    :
                    <Typography variant='body1' fontSize={18}>
                        <div dangerouslySetInnerHTML={{ __html: details.content }} />
                    </Typography>
                }
            </>
        </WebsiteContainer>
    )
}