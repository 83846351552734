import PropTypes from 'prop-types';
import { Container, CssBaseline, Grid, ThemeProvider, createTheme } from "@mui/material"
import Footer from "./footer/Footer"
import Header from "./header/Header"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router"
import { useEffect, useState } from "react"
import RenderComponent from "./RenderComponent"
import WebsiteData from "./contexts/WebsiteData"
import { ThreeDAssetsProvider } from "./hooks/ModelHook"

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: 'black',
                    '&.Mui-disabled': {
                        color: 'darkgrey !important',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root .Mui-disabled": {
                        color: "black" // (default alpha is 0.38)
                      },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-disabled  .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'darkgrey',
                        borderWidth: '1px',
                        color: 'darkgrey'
                    },
                    '&.Mui-disabled': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'darkgrey',
                            borderWidth: '1px'
                        },
                        '& .MuiInputBase-input': {
                            color: 'darkgrey'
                        }
                    },
                },
                sizeLarge: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },'&.Mui-disabled  .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'darkgrey',
                        borderWidth: '1px',
                        color: 'darkgrey'
                    },
                },
                sizeSmall: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },'&.Mui-disabled  .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'darkgrey',
                        borderWidth: '1px',
                        color: 'darkgrey'
                    },
                },
                '&.Mui-disabled .MuiInputBase-input': {
                    color: 'darkgrey !important'
                }
                
                
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#737373',
                    '&.Mui-focused': {
                        color: 'black',
                    },
                    '&.Mui-disabled': {
                        color: 'grey'
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 0, // No border radius
                    padding: '18px 24px', // Extra padding for large size
                    border: '2px solid white', // White two-pixel thick outline
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 0, // No border radius
                        border: '2px solid black', // White two-pixel thick outline
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'darkgrey',
                        color: 'rgba(255, 255, 255, 0.3)', // Optional: to fade the text color
                    },
                },
                sizeMedium: {
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 0, // No border radius
                    padding: '12px 18px', // Extra padding for large size
                    border: '2px solid white', // White two-pixel thick outline
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 0, // No border radius
                        border: '2px solid black', // White two-pixel thick outline
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'darkgrey',
                        color: 'rgba(255, 255, 255, 0.3)', // Optional: to fade the text color
                    },
                },
                sizeSmall: {
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 0, // No border radius
                    padding: '12px 16px', // Extra padding for large size
                    border: '2px solid white', // White two-pixel thick outline
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 0, // No border radius
                        border: '2px solid black', // White two-pixel thick outline
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'darkgrey',
                        color: 'rgba(255, 255, 255, 0.3)', // Optional: to fade the text color
                    },
                }
                // You can customize other button variants here if needed
            },
        },
    },
});

export default function Main({ websiteData }) {
    const { pageId } = useParams()
    const [pageDetails, setPageDetails] = useState();
    const [websiteInformation, setWebsiteInformation] = useState({});
    const [websitePages, setWebsitePages] = useState();
    const [websiteNav, setWebsiteNav] = useState();
    const [websitePath, setWebsitePath] = useState();

    useEffect(() => {
        setWebsiteNav(websiteData.nav)
        setWebsitePages(websiteData.pages.items)
        setWebsiteInformation(websiteData)
        setWebsitePath(websiteData.path)
    }, [])

    return (
        <>
            <WebsiteData.Provider value={websiteInformation}>
                <ThreeDAssetsProvider>
                    <ThemeProvider theme={theme}>
                        <Helmet>
                            <title>
                                {(websitePages && websiteInformation) ? `${websitePages?.find(page => page.id === pageId).title ? `${websitePages?.find(page => page.id === pageId).title} - ` : ''}${websiteInformation?.title}` : 'Loading...'}
                            </title>
                            <link rel="icon" href="" />
                            <link rel="icon" href={websiteInformation?.favIcon || "/favicon.ico"} />
                        </Helmet>
                        <CssBaseline />
                        <Grid container direction='column' sx={{ minHeight: '100%' }}>
                            <Grid item>
                                <Header pages={websiteNav} path={websitePath} />
                            </Grid>
                            <Grid item xs style={{ display: pageDetails ? 'block' : 'flex', flex: 1 }}>
                                <Container maxWidth disableGutters sx={{ backgroundColor: theme => theme.palette.common.white }}>
                                    {
                                        websitePages?.find(page => page.id === pageId)?.content?.map((component, index) =>
                                            <RenderComponent component={component} key={index} index={index} />
                                        )
                                    }
                                </Container>
                            </Grid>
                            <Grid item>
                                <Footer pages={websiteNav} path={websitePath} />
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </ThreeDAssetsProvider>
            </WebsiteData.Provider>
        </>
    )
}

Main.propTypes = {
    websiteData: PropTypes.object.isRequired
}