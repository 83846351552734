import PropTypes from 'prop-types';
import WebsiteContainer from '../utilities/Container';
import { Box, Card, Divider, Grid, Stack, Typography, styled, useMediaQuery } from '@mui/material';

const StyledImage = styled('img')``

function getDateInfo(inputDate) {
    // Initialize a Date object
    let date;

    // Check if the input is an ISO string (YYYY-MM-DDTHH:mm:ss.sssZ)
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z?$/.test(inputDate)) {
        date = new Date(inputDate);
    } else {
        // Attempt to parse the input date as a regular date string
        date = new Date(inputDate);
        // Check if the parsed date is invalid (NaN)
        if (isNaN(date)) {
            throw new Error('Invalid date format');
        }
    }

    // Define an array of month names
    const monthNames = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];

    // Extract the day and month information
    const day = date.getDate(); // Get the day of the month
    const month = monthNames[date.getMonth()]; // Get the month name

    // Create and return the result object
    const result = {
        day: day,
        Month: month
    };

    return result;
}

Events.propTypes = {
    details: PropTypes.object,
    index: PropTypes.number
}

export default function Events({ details, index }) {
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))

    return (
        <WebsiteContainer index={index}>
            <Typography
                variant='h4'
                fontWeight={800}
                textAlign={isMd ? 'left' : 'center'}
                sx={{
                    textDecoration: 'underline',
                    textDecorationThickness: 1,
                    textDecorationColor: theme => theme.palette.grey[400],
                    textDecorationStyle: 'solid',
                    textUnderlineOffset: 13,
                    mb: 4
                }}
            >
                Upcoming Events
            </Typography>
            {/* <Divider sx={{ my: 3, backgroundColor: theme => theme.palette.grey[400] }} /> */}

            {
                details?.events?.items?.map((event, index) =>
                    <Card elevation={6} sx={{ backgroundColor: theme => theme.palette.common.black, color: 'white', backgroundImage: 'unset', borderRadius: 0, marginBottom: details.events.items.length === index ? 0 : 3 }}>
                        <Grid container alignItems='stretch'>
                            <Grid item order={isMd ? 1 : 1} xs={6} md={3} sx={{ display: 'flex', my: 0 }}>
                                <StyledImage
                                    sx={{
                                        flex: '1', // Expand to fill the container
                                        objectFit: 'cover', // Adjust this to 'contain' if needed
                                        objectPosition: 'center',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                    src={event.image}
                                />
                            </Grid>
                            <Grid item order={isMd ? 2 : 3} p={3} xs={12} md={6}>
                                <Typography variant='h5' mb={isMd ? 3 : 1}>
                                    {event.title}
                                </Typography>
                                <Typography variant='subtitle1'>
                                    {event.description}
                                </Typography>
                            </Grid>
                            <Grid item order={isMd ? 3 : 2} xs={6} md={3} p={isMd ? 3 : 1}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        borderStyle: 'solid',
                                        borderWidth: 2
                                    }}
                                >
                                    <Stack justifyContent='center' spacing={0} alignContent='center' height='100%'>
                                        <Typography variant='h2' fontWeight={800} textAlign='center'>
                                            {getDateInfo(event.dateTime).day}
                                        </Typography>
                                        <Typography variant='subtitle1' fontWeight={800} textAlign='center'>
                                            September
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                )
            }
        </WebsiteContainer>
    )
}