import PropTypes from 'prop-types';
import WebsiteContainer from '../utilities/Container';
import { Avatar, Box, Grid, Stack, Typography, styled, useMediaQuery } from '@mui/material';

/* ---------------------------- Styled Components --------------------------- */

const StyledImage = styled('img')``

/* -------------------------------------------------------------------------- */

export default function StaffSection({ details, index }) {
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))

    if (details.style === 'avatars') {
        return (
            <WebsiteContainer index={index}>
                <Typography
                    variant='h4'
                    fontWeight={800}
                    textAlign={isMd ? 'left' : 'center'}
                    sx={{
                        textDecoration: 'underline',
                        textDecorationThickness: 1,
                        textDecorationColor: theme => theme.palette.grey[400],
                        textDecorationStyle: 'solid',
                        textUnderlineOffset: 13,
                        mb: 4
                    }}
                >
                    {details.title}
                </Typography>
                <Grid container spacing={3} justifyContent='space-around'>
                    {details?.staffMembers?.map((staffMemeber) =>
                        <Grid item xs={12} md={3}>
                            <Stack spacing={0} alignItems='center'>
                                <Avatar
                                    alt={staffMemeber.name}
                                    src={staffMemeber.image}
                                    sx={{ width: 200, height: 200, mb: 3 }}
                                />
                                <Typography variant='body1' fontSize={24} fontWeight={800} textAlign='center'>
                                    {staffMemeber?.name}
                                </Typography>
                                <Typography variant='body1' fontSize={16} sx={{ color: theme => theme.palette.grey[700] }} textAlign='center'>
                                    {staffMemeber?.title}
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </WebsiteContainer>
        )
    }

    return (
        <WebsiteContainer index={index}>
            <Typography
                variant='h4'
                fontWeight={800}
                textAlign={isMd ? 'left' : 'center'}
                sx={{
                    textDecoration: 'underline',
                    textDecorationThickness: 1,
                    textDecorationColor: theme => theme.palette.grey[400],
                    textDecorationStyle: 'solid',
                    textUnderlineOffset: 13,
                    mb: 4
                }}
            >
                {details.title}
            </Typography>
            {details?.staffMembers?.map((staffMemeber, staffIndex) =>
                <Grid container alignItems='center' mt={staffIndex >= 1 ? 5 : 0} >
                    <Grid item sx={12} md={4.5}>
                        <StyledImage src={staffMemeber.image} sx={{ height: '100%', maxHeight: '500px', width: '100%', objectFit: 'cover', objectPosition: 'center' }} alt='Geep' />
                    </Grid>
                    <Grid item sx={12} md={6.5} px={isMd ? 2 : 0}>
                        <Typography variant='body1' fontSize={32} fontWeight={800}>
                            {staffMemeber?.name}
                        </Typography>
                        <Typography variant='body1' fontSize={26} mb={1} sx={{ color: theme => theme.palette.grey[700] }}>
                            {staffMemeber?.title}
                        </Typography>
                        <Typography variant='body1' fontSize={17}>
                            {staffMemeber?.content}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </WebsiteContainer>
    )
}

StaffSection.propTypes = {
    details: PropTypes.object,
    index: PropTypes.number
}