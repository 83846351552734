import { createContext, useContext, useState, useEffect } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

// 1. Create the Context for 3D Assets
const ThreeDAssetsContext = createContext(null);

export const ThreeDAssetsProvider = ({ children }) => {
  const [model, setModel] = useState(null);
  const [textures, setTextures] = useState({
    labelTexture: null,
    diffuseTexture: null,
    metalnessTexture: null,
  });

  useEffect(() => {
    // Load the model once
    const objLoader = new OBJLoader();
    objLoader.load('/3dmodels/can.obj', loadedModel => {
      setModel(loadedModel);
    });

    // Load the textures once
    const labelTexture = new THREE.TextureLoader().load('/path/to/label.jpg'); // Adjust the path
    const diffuseTexture = new THREE.TextureLoader().load('/3dmodels/diffuse.jpeg');
    const metalnessTexture = new THREE.TextureLoader().load('/3dmodels/aluminum.jpeg');

    setTextures({
      labelTexture,
      diffuseTexture,
      metalnessTexture,
    });
  }, []);

  return (
    <ThreeDAssetsContext.Provider value={{ model, textures }}>
      {children}
    </ThreeDAssetsContext.Provider>
  );
};

// 2. Create a Custom Hook
export const useThreeDAssets = () => {
  const context = useContext(ThreeDAssetsContext);
  if (!context) {
    throw new Error('useThreeDAssets must be used within a ThreeDAssetsProvider');
  }
  return context;
};