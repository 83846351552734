import PropTypes from 'prop-types';
import WebsiteContainer from '../utilities/Container';
import { Dialog, Grid, IconButton, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import { Masonry } from '@mui/lab';
import { useState } from 'react';
import { Close } from '@mui/icons-material';

ImageGallery.propTypes = {
    details: PropTypes.object,
    index: PropTypes.number
}

export default function ImageGallery({ details, index }) {
    const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'))
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'))
    const [imageToView, setImageToView] = useState();
    const [imageOpen, setImageOpen] = useState(false);

    return (
        <WebsiteContainer maxWidth index={index}>
            <ImageList variant="masonry" cols={isLg ? 6 : isMd ? 5 : isSm ? 4 : 2} gap={8}>
                {details.images.map((item) => (
                    <ImageListItem key={item}>
                        <img
                            src={`${item}`}
                            loading="lazy"
                            onClick={() => {
                                setImageToView(item)
                                setImageOpen(true)
                            }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Dialog
                fullScreen
                open={imageOpen}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgba(0,0,0,0.95)',
                        backgroundImage: 'unset'
                    }
                }}
            >
                <Grid container direction='column' sx={{ minHeight: '100%' }}>
                    <Grid item width='100%' py={2} display={'flex'}>
                        <IconButton size='large' sx={{ ml: 'auto', mr: 4 }}>
                            <Close fontSize='medium' onClick={() => {
                                setImageOpen(false);
                                setImageToView()
                            }} />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            display: 'flex',
                            flex: 1,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            ...(imageToView &&
                            {
                                backgroundImage: `url(${imageToView})`,
                            }
                            )
                        }}
                    />
                    <Grid item width='100%' height='80px' py={2} display={'flex'}>

                    </Grid>
                </Grid>

            </Dialog>
        </WebsiteContainer>
    )
}