// Mock top-level data
export const websiteData = {
    id: 'austin-123',
    path: '',
    logo: '/logo/gt-logo.png',
    url: 'localhost:3030',
    title: "Good Times Brewery",
    companyName: 'Good Times Brewery',
    favIcon: '/favicon-beer.ico',
    hours: [
        {
            days: 'Sun-Mon',
            hours: 'Closed',
        },
        {
            days: 'Tue-Thur',
            hours: '4pm-10pm',
        },
        {
            days: 'Fri-Sat',
            hours: '11am-10pm',
        }
    ],
    beers: [
        {
            id: '213bn0rubi213aqerh',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qergergqre',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213h453',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qeheqf',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qsfqer',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qerghq345',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        }
    ],
    theme: 'goodtimes',
    socials: {
        // youtube: "https://youtube.com",
        facebook: "https://www.facebook.com/GoodTimesBrewing",
        // twitter: "https://twitter.com",
        // instagram: "https://instagram.com",
        google: "https://www.google.com/maps/place/The+Mill+House/@34.1898188,-82.1637555,15z/data=!4m5!3m4!1s0x0:0xb7ee51477d5e11e2!8m2!3d34.1898242!4d-82.163757"
    },
    nav: [
        {
            title: 'Home',
            id: 'home',
            index: 1,
            default: true
        },
        // {
        //     title: 'Our Beers',
        //     id: 'beers',
        //     index: 2,
        // },
        {
            title: 'Events & Catering',
            id: 'catering',
            url: '/catering',
            type: 'external',
            index: 3
        },

        // {
        //     title: 'Good Times Brewing',
        //     id: 'goodtimes',
        //     url: 'http://goodtimes.beer',
        //     type: 'external',
        //     index: 6,
        // },
        // {
        //     title: 'Menus',
        //     index: 4,
        //     parent: true,
        //     children: [
        //         {
        //             title: 'Beer',
        //             id: 'beermenu',
        //             index: 1,
        //         },
        //         {
        //             title: 'Food',
        //             id: 'foodmenu',
        //             index: 2,
        //         },
        //     ]
        // },
        {
            title: 'Menus',
            id: 'menus',
            index: 2,
        },
        // {
        //     title: 'Where To Buy',
        //     id: 'wtb',
        //     index: 5,
        // },
        {
            title: 'Contact',
            id: 'contact',
            index: 6,
        },
        {
            title: 'Mill House',
            id: 'millhouse',
            url: 'https://millhousepizza.com',
            type: 'external',
            index: 6,
        },
    ],
    pages: {
        items: [
            {
                id: 'home',
                title: 'Home',
                default: true,
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                                type: 'external'
                            },
                            {
                                title: 'Catering',
                                path: '/catering',
                                type: 'external'
                            },
                        ]
                    },
                    {
                        type: 'text',
                        title: 'About Us',
                        content: `The foundations of The Mill House were formed in the back yard of a home in the Harris Mill village. Around 2006, a small gas fired brick oven was built that hosted many gatherings, one pizza at a time. Good beer, good pizza, and good company were good times to be had on the mill hill. In 2009 the market took a turn for the worse, and partners Gianpaolo Bonaca and Paul Bartolomeo decided it was time to get out of the then dying real estate market and instead lose sleep over building a restaurant. So they found a building in a quiet part of town, and began working on a plan to bring the same good times from Harris Mill to what would become The Mill House.
                        <br /><br />
                        Once we (barely) got open, The Mill House began to come alive on its own. As a strong team developed, we explored new things, expanded our horizons, and our community embraced us as we grew. Since starting as a small pizza and sandwich shop, we continually broaden our menu, cultivate a fine craft beer selection, host Sunday brunches and beer dinners, and keep the Good Times brews flowing from our on-site brewery. We support local farmers by using local produce and meats when possible, and our team takes pride in the quality of our work, the freshness of our ingredients, serving our patrons.
                        <br /><br />
                        But none of this would have been possible without our dedicated team and outstanding customers. We always love to hear your feedback to help us make this a better experience for you.`,
                        image: '/images/kegs.jpeg',
                        imageRight: true
                    },
                    // {
                    //     type: 'events',
                    //     events: {
                    //         items: [
                    //             {
                    //                 image: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/1694514/settings_images/w2lBYdzITWarofBWtdUA_contact_about.png',
                    //                 title: 'Jason Williams Polka Night',
                    //                 description: `Thought the man was just a bartender? You'd be wrong. He's an electrifying performer as well.`,
                    //                 dateTime: '2023-09-12T19:30:54-04:00',
                    //             },
                    //             {
                    //                 image: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/1694514/settings_images/w2lBYdzITWarofBWtdUA_contact_about.png',
                    //                 title: 'Jason Williams Polka Night',
                    //                 description: `Thought the man was just a bartender? You'd be wrong. He's an electrifying performer as well.`,
                    //                 dateTime: '2023-09-12T19:30:54-04:00',
                    //             },
                    //             {
                    //                 image: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/1694514/settings_images/w2lBYdzITWarofBWtdUA_contact_about.png',
                    //                 title: 'Jason Williams Polka Night',
                    //                 description: `Thought the man was just a bartender? You'd be wrong. He's an electrifying performer as well.`,
                    //                 dateTime: '2023-09-12T19:30:54-04:00',
                    //             }
                    //         ]
                    //     }
                    // },
                    {
                        type: 'staff',
                        title: 'Our Staff',
                        staffMembers: [
                            {
                                name: 'John Michael McCranie',
                                title: 'Head Brewer',
                                image: '/images/john-michael.jpeg',
                                content: `John Michael has been with our team since the early brewery days in 2015... Learning the ropes on our first 3bbl system and later graduating to our now 10bbl brewhouse in 2018. He loves his craft and is a stickler for the classics. He still loathes to be called a brewmaster, though we would argue he has long since reached the requisite 10,000 hours...`
                            },
                            {
                                name: 'Gianpaolo Bonaca',
                                title: 'Owner',
                                image: '/images/geep.jpeg',
                                content: `Gianpaolo (also answers to 'GP' and 'Geep', and has been called worse) came from Columbia SC to attend Lander University. In 2001 he graduated from the school of Business, and decided to stay involved with product development in the horticulture industry. This somehow seemed to lead to projects varying from building growth chambers, to electrical contracting, to real estate investing with his partner Paul, among other things. Gianpaolo’s late father had always wanted to build a brick oven, so during the era of doing 15 projects at a time he decided to build a small oven in the back yard of his mill house in the Harris Mill Village. After many moons of teardowns, rebuilds and profanity, the oven finally ran smoothly and provided perfect pies for many gatherings. When the economy took a down turn in 2009, Gianpaolo and partner Paul decided it was time to stop flipping houses and start slinging pies. With the strong support of his wife Riley and their 2 boys, their families, the city of Greenwood, a dedicated staff and even more dedicated patrons… Gianpaolo and Paul have enjoyed a gracious experience in hospitality since 2010.`
                            }
                        ]
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/gtImages/1.jpeg',
                            '/gtImages/2.jpeg',
                            '/gtImages/3.jpeg',
                            '/gtImages/4.jpeg',
                            '/gtImages/5.jpeg',
                            '/gtImages/6.jpeg',
                            '/gtImages/7.jpeg',
                            '/gtImages/8.jpeg',
                            '/gtImages/9.jpeg',
                            '/gtImages/10.jpeg',
                            '/gtImages/11.jpeg',
                            '/gtImages/12.jpeg',
                            '/gtImages/13.jpeg',
                            '/gtImages/14.jpeg',
                            '/gtImages/15.jpeg',
                            '/gtImages/16.jpeg',
                            '/gtImages/17.jpeg',
                            '/gtImages/18.jpeg',
                            '/gtImages/19.jpeg',
                            '/gtImages/20.jpeg',
                            '/gtImages/21.jpeg',
                            '/gtImages/22.jpeg',
                            '/gtImages/23.jpeg',
                            '/gtImages/24.jpeg',
                        ]
                    },
                    {
                        type: 'contact'
                    }
                ]
            },
            {
                title: 'Our Beers',
                id: 'beers',
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                                type: 'external'
                            },
                            {
                                title: 'Catering',
                                path: '/catering',
                                type: 'external'
                            },
                        ]
                    },
                    {
                        type: 'beerCards',
                    }
                ]
            },
            {
                title: 'Events & Catering',
                id: 'catering',
                content: [
                    {
                        type: 'banner',
                        title: 'Events & Catering',
                        subtext: 'The Food And Space For Every Occasion',
                        noButtons: true,
                        image: '/images/catering.jpg',
                    },
                    {
                        type: 'text',
                        title: 'Catering',
                        content: `Good Times Brewing is a venue and catering service that sets itself apart from other experiences, combining all the elements to make your event special under one roof... or if you prefer, under another roof. Our unique combination of brewery, restaurant, cocktail service and tap room gives us the ability to provide distinctive events for up to 100 people in a beautifully restored rustic space in Uptown Greenwood. Need to feed more people for your event? Our team can also provide off site catering at the venue of your choosing.<br /><br />Share the vision of your event with us and we can help you craft a menu and design a space to both meet your needs and impress your guests!`,
                        image: '/cateringImages/7.jpg',
                        imageRight: false
                    },
                    {
                        type: 'menu',
                        title: 'Catering Menu',
                        categories: [
                            {
                                title: 'Trays',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Charcuterie Board',
                                        price: '250'
                                    },
                                    {
                                        title: 'Fruit Tray',
                                        price: '65'
                                    },
                                    {
                                        title: 'Vegetable Tray',
                                        price: '50'
                                    },
                                ]
                            },
                            {
                                title: 'Dips',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Fresh Made Hummus',
                                        description: 'Served with pita and veggies',
                                        price: '60'
                                    },
                                    {
                                        title: 'Warm Pimento Cheese',
                                        description: 'Served with pita chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Spinach and Artichoke Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Buffalo Chicken Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                ]
                            },
                            {
                                title: `Hors D'oeuvres`,
                                subtitle: 'three for $24 per person or four for $32 per person',
                                items: [
                                    {
                                        title: 'Antipasto skewers',
                                        description: 'Sun dried tomatoes, fresh mozzarella, and marinated artichoke hearts'
                                    },
                                    {
                                        title: 'Ham and Cheese Stuffed Mushrooms'
                                    },
                                    {
                                        title: 'Chicken Tenders'
                                    },
                                    {
                                        title: 'Wings',
                                        description: 'Served with two sauces and two dressings'
                                    },
                                    {
                                        title: 'Beef Sliders',
                                        description: 'Served with lettuce, tomato, pickles, mayo, mustard, ketchup, and cheese'
                                    },
                                    {
                                        title: 'Meatballs with marinara'
                                    },
                                    {
                                        title: 'Pigs in a Blanket'
                                    },
                                    {
                                        title: 'Crescent Wrapped Asparagus',
                                        description: 'Served with herbed cheese spread'
                                    },
                                    {
                                        title: 'Chicken Salad',
                                        description: 'Served with croissants'
                                    },
                                    {
                                        title: 'Assorted Mini Quiche'
                                    },
                                    {
                                        title: 'Prosciutto Wrapped Asparagus',
                                        description: 'With parmesan and balsamic glaze'
                                    },
                                ]
                            },
                            {
                                title: `More Options`,
                                subtitle: 'three for $30 per person or four for $40 per person',
                                items: [
                                    {
                                        title: 'Poached Shrimp Cocktail',
                                        description: 'With remoulade, lemons, cocktail sauce, and butter'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With remoulade or dijon cream sauce'
                                    },
                                    {
                                        title: 'Seared Ahi Tuna',
                                        description: 'Sesame crusted or blackend'
                                    },
                                    {
                                        title: 'Bacon Wrapped Scallops',
                                    },
                                ]
                            },
                            {
                                title: `Homestyle Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Pulled Pork or BBQ Chicken',
                                        description: 'Served with toasted bun, baked beans, potato salad or coleslaw, and mac and cheese'
                                    },
                                    {
                                        title: 'Soft Taco Bar with Chicken or Beef',
                                        description: 'Comes with spanish rice, black beans, and churros, lettuce, tomato, cheese, sour cream and salsa provided.'
                                    },
                                    {
                                        title: 'Spaghetti and Meatballs',
                                        description: 'Served with marinara or alfredo, italian style green beans (prosciutto and red onions), or roasted seasonal vegetables, garden salad, and garlic bread'
                                    },
                                    {
                                        title: 'BYO Burger Bar (Beef Burgers or Grilled Chicken)',
                                        description: 'Served with brioche bun, coleslaw or potato salad, mac and cheese, and garden salad. Toppings include: lettuce, tomato, red onion, cheeses, pickles, mayo, mustard, and ketchup'
                                    },
                                    {
                                        title: 'Hamburger Steaks',
                                        description: 'With brown onion gravy, mashed potatoes, green beans, and rolls'
                                    },
                                ]
                            },
                            {
                                title: `Upscale Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Shrimp and Grits',
                                        description: 'Served with cheese, bacon, scallions, and diced tomatoes'
                                    },
                                    {
                                        title: 'Eggplant Parmesan',
                                        description: 'Stuffed with ricotta cheese'
                                    },
                                    {
                                        title: 'Braised, Grilled, or Roasted Chicken Thighs'
                                    },
                                    {
                                        title: 'Caprese Chicken'
                                    },
                                    {
                                        title: 'Chicken Marsala',
                                        description: 'With mushroom marsala wine sauce'
                                    },
                                    {
                                        title: 'Pork Tenderloin',
                                        description: 'With sauteed apples & onions, bacon, molasses & tomato chutney *Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Shrimp Scampi',
                                        description: 'With choice of vegetable, angel hair or linguine pasta, Italian or garlic bread'
                                    },
                                    {
                                        title: 'Filet Tips'
                                    },
                                    {
                                        title: 'Whole Filet Mignon',
                                        description: 'Carved and served with horseradish cream sauce & demi glace ($75 for carving attendant)'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With Remoulade or Dijon Cream Sauce'
                                    },
                                    {
                                        title: 'Chicken Piccata (capers, white wine and lemon sauce)',
                                        description: 'Served with Bowtie, Farfalle, or Fettucine'
                                    },
                                    {
                                        title: 'Starches',
                                        description: 'Mac and Cheese, Oven Roasted Rosemary Potatoes, Scalloped Potatoes, Mashed Potato Bar, Mashed Potatoes, Rice Pilaf, Grits, and Sweet Potato Souffle'
                                    },
                                    {
                                        title: 'Vegetables',
                                        description: 'Roasted Asparagus, Mixed Vegetables, Glazed Carrots, Roasted Brussel Sprouts, Green Peas, and Green Beans'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: 'staff',
                        title: 'Our Staff',
                        style: 'avatars',
                        staffMembers: [
                            {
                                name: 'Amber Taylor',
                                title: 'Event Coordinator',
                                image: '/images/amber.jpeg',
                            },
                            {
                                name: 'Tom Jackson',
                                title: 'Event Chef',
                                image: '/images/tom.jpg',
                            },
                            {
                                name: 'Alex Giglotti',
                                title: 'Event Chef',
                                image: '/images/alex.jpeg',
                            },
                        ]
                    },
                    {
                        type: 'contact',
                        from: 'catering',
                        style: 'simple',
                        email: 'goodtimescateredevents@gmail.com',
                        phone: '3039142002'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/cateringImages/1.jpg',
                            '/cateringImages/2.jpg',
                            '/cateringImages/3.jpg',
                            '/cateringImages/4.jpg',
                            '/cateringImages/5.jpg',
                            '/cateringImages/6.jpg',
                            '/cateringImages/7.jpg',
                            '/cateringImages/8.jpg',
                            '/cateringImages/9.jpg',
                            '/cateringImages/10.jpg',
                            '/cateringImages/11.jpg',
                            '/cateringImages/12.jpg',
                            '/cateringImages/13.jpg',
                            '/cateringImages/14.jpg',
                            '/cateringImages/15.jpg',
                            '/cateringImages/16.jpg',
                            '/cateringImages/17.jpg',
                        ]
                    }
                ]
            },
            {
                title: 'Food Menu',
                id: 'menus',
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                                type: 'external'
                            },
                            {
                                title: 'Catering',
                                path: '/catering',
                                type: 'external'
                            },
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'Food Menu',
                        orderLink: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                        categories: [
                            {
                                title: 'Snacks',
                                items: [
                                    {
                                        title: 'Fried Pickle Chips',
                                        price: '9'
                                    },
                                    {
                                        title: 'GT Onion Rings',
                                        price: '8'
                                    },
                                    {
                                        title: 'Garlic Parmesan Fries',
                                        price: '8'
                                    },
                                    {
                                        title: 'Sweet Potato Fries',
                                        description: 'Served with a maple-bacon dipping sauce',
                                        price: '8'
                                    },
                                    {
                                        title: 'Plain Fries',
                                        description: '($2 upcharge cheese & bacon)',
                                        price: '7'
                                    },
                                    {
                                        title: 'Plain Tots',
                                        description: '($2 upcharge buffalo tots)',
                                        price: '7'
                                    },
                                    {
                                        title: 'Beer Cheese Tots',
                                        description: 'bacon & beer cheese',
                                        price: '8'
                                    },
                                    {
                                        title: 'Giant Pretzel',
                                        description: 'Mustard & GT beer cheese',
                                        price: '9'
                                    },
                                    {
                                        title: 'Chicken Tenders',
                                        description: 'GT BBQ sauce',
                                        price: '9'
                                    },
                                    {
                                        title: 'Good Times Nachos',
                                        description: 'Queso, GT beer cheese, pico, jalapeños, fiesta blend. house corn chips. ($3 upcharge for protein)',
                                        price: '9'
                                    },
                                    {
                                        title: 'Fried Buffalo Brussel Sprouts',
                                        description: 'Bacon, bleu cheese crumbs',
                                        price: '9'
                                    },
                                    {
                                        title: 'Wings (6)',
                                        description: 'lemon pepper, garlic parm, buffalo, GT bbq, honey soy',
                                        price: '11'
                                    },
                                    {
                                        title: 'Wings (12)',
                                        description: 'lemon pepper, garlic parm, buffalo, GT bbq, honey soy',
                                        price: '19'
                                    },
                                ]
                            },
                            {
                                title: 'Build Your Own Burger',
                                subtitle: 'Comes with a side',
                                items: [
                                    {
                                        title: 'Meat',
                                        description: 'Burger: $12.50, Chicken: $12, Fried Cod: $12',
                                    },
                                    {
                                        title: 'Cheese',
                                        description: 'Pepper jack, swiss, american, chedday, mozzarella, bleu cheese crumbs',
                                    },
                                    {
                                        title: 'Upcharge Toppings',
                                        description: 'Sauteed onions, sauteed mushrooms, crispy onions, jalapeños, spinach, onion rings, fiesta blend',
                                        price: '2'
                                    },
                                    {
                                        title: 'Premium Sauces',
                                        description: 'Queso, sriracha aioli, GT beer cheese, maple-bacon, cilantro lime, tex-mex',
                                        price: '1.50'
                                    },
                                ]
                            },
                            {
                                title: 'Handhelds',
                                subtitle: 'Comes with a side',
                                items: [
                                    {
                                        title: 'Candied Bacon Burger',
                                        description: 'Candied bacon, cheddar, lettuce, tomato, onion.',
                                        price: '13'
                                    },
                                    {
                                        title: 'Mac & Cheese Burger',
                                        description: 'Mac & cheese, american cheese, bacon, crispy onion, sriracha aioli',
                                        price: '14'
                                    },
                                    {
                                        title: 'Hot Honey Chicken Sandwich',
                                        description: 'Fried or grilled, pickles, sriracha aioli',
                                        price: '15'
                                    },
                                    {
                                        title: 'GT Pulled Brisket Tacos',
                                        description: 'Fiesta mix, tex mex sauce, pico',
                                        price: '14'
                                    },
                                    {
                                        title: 'GT American Quesadilla',
                                        description: 'brisket & chicken, tex mex, pico',
                                        price: '14'
                                    },
                                    {
                                        title: 'Fish and Chips',
                                        description: 'Fried cod, old bay fries, tartar sauce',
                                        price: '15'
                                    },
                                    {
                                        title: 'Philly Cheesesteak',
                                        description: 'Shaved sirloin, fried onions, american cheese, mayo & ketchup',
                                        price: '15'
                                    },
                                    {
                                        title: 'Tuna Poke Bowl',
                                        description: 'White rice, cucumber, pickled slaw, marinated tuna, avocado creme with white & black sesame seeds',
                                        price: '15'
                                    },
                                ]
                            },
                            {
                                title: 'Sides',
                                items: [
                                    {
                                        title: 'Regular Sides',
                                        description: 'Plain Fries, Tots, or Onion Rings',
                                    },
                                    {
                                        title: 'Premium Sides',
                                        description: 'Garlic parmesan fries, sweet potato fries, GT beer cheese, fried pickles, fried brussel sprouts',
                                        price: '2'
                                    },
                                ]
                            },
                            {
                                title: 'Veggie Option',
                                items: [
                                    {
                                        title: 'Roasted Veggie Plate',
                                        description: 'Large portabella mushroom filled w/ tomatoes, peppers, onions & asparagus. (add tofu $5)',
                                        price: '14'
                                    },
                                ]
                            },
                            {
                                title: 'Salads',
                                items: [
                                    {
                                        title: 'GT Cobb Salad',
                                        description: 'Tomatoes, onions, avocado, cucumbers, egg, bacon. Ranch or blue cheese',
                                        price: '12.50'
                                    },
                                    {
                                        title: 'Southwest Salad',
                                        description: 'Romaine, fiesta blend, tomato, cilanto lime. $5 chicken/$8 tuna)',
                                        price: '9'
                                    },
                                ]
                            },
                            {
                                title: 'Dessert',
                                items: [
                                    {
                                        title: 'Apple Pie Slice',
                                        price: '5'
                                    },
                                    {
                                        title: 'Chocolate Cream Pie Slice',
                                        price: '5'
                                    },
                                    {
                                        title: '5 Deep Fried Oreos',
                                        price: '12'
                                    },
                                    {
                                        title: 'Cheese Brownie Bars (2 Piece)',
                                        description: 'Topped with chociolate or caramel sauce',
                                        price: '5'
                                    },
                                    {
                                        title: 'Cheese Brownie Bars (4 Pieces)',
                                        description: 'Topped with chociolate or caramel sauce',
                                        price: '10'
                                    },
                                ]
                            },
                            {
                                title: 'Drinks',
                                items: [
                                    {
                                        title: 'Coca Cola Products',
                                        description: 'Sprite, mr. pibb, coke, diet coke, mellow yellow, lemonade',
                                        price: '3'
                                    },
                                    {
                                        title: 'Sweet & Unsweet Tea',
                                        price: '3'
                                    },
                                ]
                            },
                            {
                                title: 'Saturday Brunch',
                                description: '11am-2pm',
                                items: [
                                    {
                                        title: 'Breakfast Quesadilla',
                                        description: 'Bacon, eggs & cheese. topped w/ lettuce, salsa & sour cream.',
                                        price: '12'
                                    },
                                    {
                                        title: 'BEC Sandwich',
                                        description: 'bacon, egg & cheese on toast. GT home-fried potatoes',
                                        price: '10'
                                    },
                                    {
                                        title: 'French Toast Sticks',
                                        description: 'Covered in a berry mix. Bacon slices.',
                                        price: '10'
                                    },
                                    {
                                        title: 'GT Brunch Burger',
                                        description: 'Burger, fried egg, bacon, american cheese, crispy onions & sriracha aioli. Comes with a side',
                                        price: '14'
                                    },
                                    {
                                        title: 'Breakfast Tots',
                                        description: 'Scrambled eggs, GT beer cheese, bacon & green onions.',
                                        price: '8'
                                    },
                                    {
                                        title: 'GT Brunch Special',
                                        description: 'Rotating Special. See bartender.'
                                    },
                                ]
                            },
                            {
                                title: 'Saturday Brunch Drinks',
                                description: '11am-2pm',
                                items: [
                                    {
                                        title: 'Mimosa',
                                        description: 'Champagne and OJ',
                                        price: '8'
                                    },
                                    {
                                        title: 'Manmosa',
                                        description: 'Good Times beer and OJ',
                                        price: '8'
                                    },
                                    {
                                        title: 'Bloody Mary',
                                        description: 'Vodka, bloody mix, skewer',
                                        price: '9'
                                    },
                                    {
                                        title: 'Bloody Maria',
                                        description: 'Tequila, bloody mix, skewer',
                                        price: '10'
                                    },
                                    {
                                        title: 'Michelada',
                                        description: 'GT beer, bloody mix',
                                        price: '8'
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'Catering Menu',
                        categories: [
                            {
                                title: 'Trays',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Charcuterie Board',
                                        price: '250'
                                    },
                                    {
                                        title: 'Fruit Tray',
                                        price: '65'
                                    },
                                    {
                                        title: 'Vegetable Tray',
                                        price: '50'
                                    },
                                ]
                            },
                            {
                                title: 'Dips',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Fresh Made Hummus',
                                        description: 'Served with pita and veggies',
                                        price: '60'
                                    },
                                    {
                                        title: 'Warm Pimento Cheese',
                                        description: 'Served with pita chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Spinach and Artichoke Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Buffalo Chicken Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                ]
                            },
                            {
                                title: `Hors D'oeuvres`,
                                subtitle: 'Three for $24 per person or four for $32 per person',
                                items: [
                                    {
                                        title: 'Antipasto skewers',
                                        description: 'Sun dried tomatoes, fresh mozzarella, and marinated artichoke hearts'
                                    },
                                    {
                                        title: 'Ham and Cheese Stuffed Mushrooms'
                                    },
                                    {
                                        title: 'Chicken Tenders'
                                    },
                                    {
                                        title: 'Wings',
                                        description: 'Served with two sauces and two dressings'
                                    },
                                    {
                                        title: 'Beef Sliders',
                                        description: 'Served with lettuce, tomato, pickles, mayo, mustard, ketchup, and cheese'
                                    },
                                    {
                                        title: 'Meatballs with marinara'
                                    },
                                    {
                                        title: 'Pigs in a Blanket'
                                    },
                                    {
                                        title: 'Crescent Wrapped Asparagus',
                                        description: 'Served with herbed cheese spread'
                                    },
                                    {
                                        title: 'Chicken Salad',
                                        description: 'Served with croissants'
                                    },
                                    {
                                        title: 'Assorted Mini Quiche'
                                    },
                                    {
                                        title: 'Prosciutto Wrapped Asparagus',
                                        description: 'With parmesan and balsamic glaze'
                                    },
                                ]
                            },
                            {
                                title: `More Options`,
                                subtitle: 'Three for $30 per person or four for $40 per person',
                                items: [
                                    {
                                        title: 'Poached Shrimp Cocktail',
                                        description: 'With remoulade, lemons, cocktail sauce, and butter'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With remoulade or dijon cream sauce'
                                    },
                                    {
                                        title: 'Seared Ahi Tuna',
                                        description: 'Sesame crusted or blackend'
                                    },
                                    {
                                        title: 'Bacon Wrapped Scallops',
                                    },
                                ]
                            },
                            {
                                title: `Homestyle Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Pulled Pork or BBQ Chicken',
                                        description: 'Served with toasted bun, baked beans, potato salad or coleslaw, and mac and cheese'
                                    },
                                    {
                                        title: 'Soft Taco Bar with Chicken or Beef',
                                        description: 'Comes with spanish rice, black beans, and churros, lettuce, tomato, cheese, sour cream and salsa provided.'
                                    },
                                    {
                                        title: 'Spaghetti and Meatballs',
                                        description: 'Served with marinara or alfredo, italian style green beans (prosciutto and red onions), or roasted seasonal vegetables, garden salad, and garlic bread'
                                    },
                                    {
                                        title: 'BYO Burger Bar (Beef Burgers or Grilled Chicken)',
                                        description: 'Served with brioche bun, coleslaw or potato salad, mac and cheese, and garden salad. Toppings include: lettuce, tomato, red onion, cheeses, pickles, mayo, mustard, and ketchup'
                                    },
                                    {
                                        title: 'Hamburger Steaks',
                                        description: 'With brown onion gravy, mashed potatoes, green beans, and rolls'
                                    },
                                ]
                            },
                            {
                                title: `Upscale Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Shrimp and Grits',
                                        description: 'Served with cheese, bacon, scallions, and diced tomatoes'
                                    },
                                    {
                                        title: 'Eggplant Parmesan',
                                        description: 'Stuffed with ricotta cheese'
                                    },
                                    {
                                        title: 'Braised, Grilled, or Roasted Chicken Thighs'
                                    },
                                    {
                                        title: 'Caprese Chicken'
                                    },
                                    {
                                        title: 'Chicken Marsala',
                                        description: 'With mushroom marsala wine sauce'
                                    },
                                    {
                                        title: 'Pork Tenderloin',
                                        description: 'With sauteed apples & onions, bacon, molasses & tomato chutney *Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Shrimp Scampi',
                                        description: 'With choice of vegetable, angel hair or linguine pasta, Italian or garlic bread'
                                    },
                                    {
                                        title: 'Filet Tips'
                                    },
                                    {
                                        title: 'Whole Filet Mignon',
                                        description: 'Carved and served with horseradish cream sauce & demi glace ($75 for carving attendant)'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With Remoulade or Dijon Cream Sauce'
                                    },
                                    {
                                        title: 'Chicken Piccata (capers, white wine and lemon sauce)',
                                        description: 'Served with Bowtie, Farfalle, or Fettucine'
                                    },
                                    {
                                        title: 'Starches',
                                        description: 'Mac and Cheese, Oven Roasted Rosemary Potatoes, Scalloped Potatoes, Mashed Potato Bar, Mashed Potatoes, Rice Pilaf, Grits, and Sweet Potato Souffle'
                                    },
                                    {
                                        title: 'Vegetables',
                                        description: 'Roasted Asparagus, Mixed Vegetables, Glazed Carrots, Roasted Brussel Sprouts, Green Peas, and Green Beans'
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Beer Menu',
                id: 'beermenu',
                content: []
            },
            {
                title: 'Contact',
                id: 'contact',
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                                type: 'external'
                            },
                            {
                                title: 'Catering',
                                path: '/catering',
                                type: 'external'
                            },
                        ]
                    },
                    {
                        type: 'contact'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/gtImages/1.jpeg',
                            '/gtImages/2.jpeg',
                            '/gtImages/3.jpeg',
                            '/gtImages/4.jpeg',
                            '/gtImages/5.jpeg',
                            '/gtImages/6.jpeg',
                            '/gtImages/7.jpeg',
                            '/gtImages/8.jpeg',
                            '/gtImages/9.jpeg',
                            '/gtImages/10.jpeg',
                            '/gtImages/11.jpeg',
                            '/gtImages/12.jpeg',
                            '/gtImages/13.jpeg',
                            '/gtImages/14.jpeg',
                            '/gtImages/15.jpeg',
                            '/gtImages/16.jpeg',
                            '/gtImages/17.jpeg',
                            '/gtImages/18.jpeg',
                            '/gtImages/19.jpeg',
                            '/gtImages/20.jpeg',
                            '/gtImages/21.jpeg',
                            '/gtImages/22.jpeg',
                            '/gtImages/23.jpeg',
                            '/gtImages/24.jpeg',
                        ]
                    }
                ]
            },
        ]
    }
}

export const cateringData = {
    id: 'catering-123',
    path: '',
    logo: '/logo/gtc-logo.png',
    url: 'localhost:3030',
    title: "Good Times Catering",
    companyName: 'Good Times Catering',
    favIcon: '/favicon-beer.ico',
    hours: [

    ],
    beers: [
        {
            id: '213bn0rubi213aqerh',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qergergqre',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213h453',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qeheqf',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qsfqer',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qerghq345',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        }
    ],
    theme: 'goodtimes',
    socials: {
        // youtube: "https://youtube.com",
        facebook: "https://www.facebook.com/GoodTimesBrewing",
        // twitter: "https://twitter.com",
        // instagram: "https://instagram.com",
        google: "https://www.google.com/maps/place/The+Mill+House/@34.1898188,-82.1637555,15z/data=!4m5!3m4!1s0x0:0xb7ee51477d5e11e2!8m2!3d34.1898242!4d-82.163757"
    },
    nav: [
        {
            title: 'Home',
            id: 'home',
            index: 1,
            default: true
        },
        {
            title: 'Contact',
            id: 'contact',
            index: 2,
        },
        {
            title: 'Good Times Brewing',
            id: 'millhouse',
            url: 'http://goodtimes.beer',
            type: 'external',
            index: 3,
        },
        {
            title: 'Mill House',
            id: 'millhouse',
            url: 'https://millhousepizza.com',
            type: 'external',
            index: 3,
        },
    ],
    pages: {
        items: [
            {
                id: 'home',
                title: 'Home',
                default: true,
                content: [
                    {
                        type: 'banner',
                        title: 'Events & Catering',
                        subtext: 'The Food And Space For Every Occasion',
                        noButtons: true,
                        image: '/images/catering.jpg',
                    },
                    {
                        type: 'text',
                        title: 'Catering',
                        content: `Good Times Brewing is a venue and catering service that sets itself apart from other experiences, combining all the elements to make your event special under one roof... or if you prefer, under another roof. Our unique combination of brewery, restaurant, cocktail service and tap room gives us the ability to provide distinctive events for up to 100 people in a beautifully restored rustic space in Uptown Greenwood. Need to feed more people for your event? Our team can also provide off site catering at the venue of your choosing.<br /><br />Share the vision of your event with us and we can help you craft a menu and design a space to both meet your needs and impress your guests!`,
                        image: '/cateringImages/7.jpg',
                        imageRight: false
                    },
                    {
                        type: 'menu',
                        title: 'Catering Menu',
                        categories: [
                            {
                                title: 'Trays',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Charcuterie Board',
                                        price: '250'
                                    },
                                    {
                                        title: 'Fruit Tray',
                                        price: '65'
                                    },
                                    {
                                        title: 'Vegetable Tray',
                                        price: '50'
                                    },
                                ]
                            },
                            {
                                title: 'Dips',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Fresh Made Hummus',
                                        description: 'Served with pita and veggies',
                                        price: '60'
                                    },
                                    {
                                        title: 'Warm Pimento Cheese',
                                        description: 'Served with pita chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Spinach and Artichoke Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Buffalo Chicken Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                ]
                            },
                            {
                                title: `Hors D'oeuvres`,
                                subtitle: 'three for $24 per person or four for $32 per person',
                                items: [
                                    {
                                        title: 'Antipasto skewers',
                                        description: 'Sun dried tomatoes, fresh mozzarella, and marinated artichoke hearts'
                                    },
                                    {
                                        title: 'Ham and Cheese Stuffed Mushrooms'
                                    },
                                    {
                                        title: 'Chicken Tenders'
                                    },
                                    {
                                        title: 'Wings',
                                        description: 'Served with two sauces and two dressings'
                                    },
                                    {
                                        title: 'Beef Sliders',
                                        description: 'Served with lettuce, tomato, pickles, mayo, mustard, ketchup, and cheese'
                                    },
                                    {
                                        title: 'Meatballs with marinara'
                                    },
                                    {
                                        title: 'Pigs in a Blanket'
                                    },
                                    {
                                        title: 'Crescent Wrapped Asparagus',
                                        description: 'Served with herbed cheese spread'
                                    },
                                    {
                                        title: 'Chicken Salad',
                                        description: 'Served with croissants'
                                    },
                                    {
                                        title: 'Assorted Mini Quiche'
                                    },
                                    {
                                        title: 'Prosciutto Wrapped Asparagus',
                                        description: 'With parmesan and balsamic glaze'
                                    },
                                ]
                            },
                            {
                                title: `More Options`,
                                subtitle: 'three for $30 per person or four for $40 per person',
                                items: [
                                    {
                                        title: 'Poached Shrimp Cocktail',
                                        description: 'With remoulade, lemons, cocktail sauce, and butter'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With remoulade or dijon cream sauce'
                                    },
                                    {
                                        title: 'Seared Ahi Tuna',
                                        description: 'Sesame crusted or blackend'
                                    },
                                    {
                                        title: 'Bacon Wrapped Scallops',
                                    },
                                ]
                            },
                            {
                                title: `Homestyle Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Pulled Pork or BBQ Chicken',
                                        description: 'Served with toasted bun, baked beans, potato salad or coleslaw, and mac and cheese'
                                    },
                                    {
                                        title: 'Soft Taco Bar with Chicken or Beef',
                                        description: 'Comes with spanish rice, black beans, and churros, lettuce, tomato, cheese, sour cream and salsa provided.'
                                    },
                                    {
                                        title: 'Spaghetti and Meatballs',
                                        description: 'Served with marinara or alfredo, italian style green beans (prosciutto and red onions), or roasted seasonal vegetables, garden salad, and garlic bread'
                                    },
                                    {
                                        title: 'BYO Burger Bar (Beef Burgers or Grilled Chicken)',
                                        description: 'Served with brioche bun, coleslaw or potato salad, mac and cheese, and garden salad. Toppings include: lettuce, tomato, red onion, cheeses, pickles, mayo, mustard, and ketchup'
                                    },
                                    {
                                        title: 'Hamburger Steaks',
                                        description: 'With brown onion gravy, mashed potatoes, green beans, and rolls'
                                    },
                                ]
                            },
                            {
                                title: `Upscale Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Shrimp and Grits',
                                        description: 'Served with cheese, bacon, scallions, and diced tomatoes'
                                    },
                                    {
                                        title: 'Eggplant Parmesan',
                                        description: 'Stuffed with ricotta cheese'
                                    },
                                    {
                                        title: 'Braised, Grilled, or Roasted Chicken Thighs'
                                    },
                                    {
                                        title: 'Caprese Chicken'
                                    },
                                    {
                                        title: 'Chicken Marsala',
                                        description: 'With mushroom marsala wine sauce'
                                    },
                                    {
                                        title: 'Pork Tenderloin',
                                        description: 'With sauteed apples & onions, bacon, molasses & tomato chutney *Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Shrimp Scampi',
                                        description: 'With choice of vegetable, angel hair or linguine pasta, Italian or garlic bread'
                                    },
                                    {
                                        title: 'Filet Tips'
                                    },
                                    {
                                        title: 'Whole Filet Mignon',
                                        description: 'Carved and served with horseradish cream sauce & demi glace ($75 for carving attendant)'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With Remoulade or Dijon Cream Sauce'
                                    },
                                    {
                                        title: 'Chicken Piccata (capers, white wine and lemon sauce)',
                                        description: 'Served with Bowtie, Farfalle, or Fettucine'
                                    },
                                    {
                                        title: 'Starches',
                                        description: 'Mac and Cheese, Oven Roasted Rosemary Potatoes, Scalloped Potatoes, Mashed Potato Bar, Mashed Potatoes, Rice Pilaf, Grits, and Sweet Potato Souffle'
                                    },
                                    {
                                        title: 'Vegetables',
                                        description: 'Roasted Asparagus, Mixed Vegetables, Glazed Carrots, Roasted Brussel Sprouts, Green Peas, and Green Beans'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: 'staff',
                        title: 'Our Staff',
                        style: 'avatars',
                        staffMembers: [
                            {
                                name: 'Amber Taylor',
                                title: 'Event Coordinator',
                                image: '/images/amber.jpeg',
                            },
                            {
                                name: 'Tom Jackson',
                                title: 'Event Chef',
                                image: '/images/tom.jpg',
                            },
                            {
                                name: 'Alex Giglotti',
                                title: 'Event Chef',
                                image: '/images/alex.jpeg',
                            },
                        ]
                    },
                    {
                        type: 'contact',
                        from: 'catering',
                        style: 'simple',
                        email: 'goodtimescateredevents@gmail.com',
                        phone: '3039142002'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/cateringImages/1.jpg',
                            '/cateringImages/2.jpg',
                            '/cateringImages/3.jpg',
                            '/cateringImages/4.jpg',
                            '/cateringImages/5.jpg',
                            '/cateringImages/6.jpg',
                            '/cateringImages/7.jpg',
                            '/cateringImages/8.jpg',
                            '/cateringImages/9.jpg',
                            '/cateringImages/10.jpg',
                            '/cateringImages/11.jpg',
                            '/cateringImages/12.jpg',
                            '/cateringImages/13.jpg',
                            '/cateringImages/14.jpg',
                            '/cateringImages/15.jpg',
                            '/cateringImages/16.jpg',
                            '/cateringImages/17.jpg',
                        ]
                    }
                ]
            },
            {
                title: 'Our Beers',
                id: 'beers',
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                                type: 'external'
                            },
                            {
                                title: 'Catering',
                                path: '/catering',
                                type: 'external'
                            },
                        ]
                    },
                    {
                        type: 'beerCards',
                    }
                ]
            },
            {
                title: 'Events & Catering',
                id: 'catering',
                content: [
                    {
                        type: 'banner',
                        title: 'Events & Catering',
                        subtext: 'The Food And Space For Every Occasion',
                        noButtons: true,
                        image: '/images/catering.jpg',
                    },
                    {
                        type: 'text',
                        title: 'Catering',
                        content: `Good Times Brewing is a venue and catering service that sets itself apart from other experiences, combining all the elements to make your event special under one roof... or if you prefer, under another roof. Our unique combination of brewery, restaurant, cocktail service and tap room gives us the ability to provide distinctive events for up to 100 people in a beautifully restored rustic space in Uptown Greenwood. Need to feed more people for your event? Our team can also provide off site catering at the venue of your choosing.<br /><br />Share the vision of your event with us and we can help you craft a menu and design a space to both meet your needs and impress your guests!`,
                        image: '/cateringImages/7.jpg',
                        imageRight: false
                    },
                    {
                        type: 'menu',
                        title: 'Catering Menu',
                        categories: [
                            {
                                title: 'Trays',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Charcuterie Board',
                                        price: '250'
                                    },
                                    {
                                        title: 'Fruit Tray',
                                        price: '65'
                                    },
                                    {
                                        title: 'Vegetable Tray',
                                        price: '50'
                                    },
                                ]
                            },
                            {
                                title: 'Dips',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Fresh Made Hummus',
                                        description: 'Served with pita and veggies',
                                        price: '60'
                                    },
                                    {
                                        title: 'Warm Pimento Cheese',
                                        description: 'Served with pita chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Spinach and Artichoke Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Buffalo Chicken Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                ]
                            },
                            {
                                title: `Hors D'oeuvres`,
                                subtitle: 'three for $24 per person or four for $32 per person',
                                items: [
                                    {
                                        title: 'Antipasto skewers',
                                        description: 'Sun dried tomatoes, fresh mozzarella, and marinated artichoke hearts'
                                    },
                                    {
                                        title: 'Ham and Cheese Stuffed Mushrooms'
                                    },
                                    {
                                        title: 'Chicken Tenders'
                                    },
                                    {
                                        title: 'Wings',
                                        description: 'Served with two sauces and two dressings'
                                    },
                                    {
                                        title: 'Beef Sliders',
                                        description: 'Served with lettuce, tomato, pickles, mayo, mustard, ketchup, and cheese'
                                    },
                                    {
                                        title: 'Meatballs with marinara'
                                    },
                                    {
                                        title: 'Pigs in a Blanket'
                                    },
                                    {
                                        title: 'Crescent Wrapped Asparagus',
                                        description: 'Served with herbed cheese spread'
                                    },
                                    {
                                        title: 'Chicken Salad',
                                        description: 'Served with croissants'
                                    },
                                    {
                                        title: 'Assorted Mini Quiche'
                                    },
                                    {
                                        title: 'Prosciutto Wrapped Asparagus',
                                        description: 'With parmesan and balsamic glaze'
                                    },
                                ]
                            },
                            {
                                title: `More Options`,
                                subtitle: 'three for $30 per person or four for $40 per person',
                                items: [
                                    {
                                        title: 'Poached Shrimp Cocktail',
                                        description: 'With remoulade, lemons, cocktail sauce, and butter'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With remoulade or dijon cream sauce'
                                    },
                                    {
                                        title: 'Seared Ahi Tuna',
                                        description: 'Sesame crusted or blackend'
                                    },
                                    {
                                        title: 'Bacon Wrapped Scallops',
                                    },
                                ]
                            },
                            {
                                title: `Homestyle Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Pulled Pork or BBQ Chicken',
                                        description: 'Served with toasted bun, baked beans, potato salad or coleslaw, and mac and cheese'
                                    },
                                    {
                                        title: 'Soft Taco Bar with Chicken or Beef',
                                        description: 'Comes with spanish rice, black beans, and churros, lettuce, tomato, cheese, sour cream and salsa provided.'
                                    },
                                    {
                                        title: 'Spaghetti and Meatballs',
                                        description: 'Served with marinara or alfredo, italian style green beans (prosciutto and red onions), or roasted seasonal vegetables, garden salad, and garlic bread'
                                    },
                                    {
                                        title: 'BYO Burger Bar (Beef Burgers or Grilled Chicken)',
                                        description: 'Served with brioche bun, coleslaw or potato salad, mac and cheese, and garden salad. Toppings include: lettuce, tomato, red onion, cheeses, pickles, mayo, mustard, and ketchup'
                                    },
                                    {
                                        title: 'Hamburger Steaks',
                                        description: 'With brown onion gravy, mashed potatoes, green beans, and rolls'
                                    },
                                ]
                            },
                            {
                                title: `Upscale Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Shrimp and Grits',
                                        description: 'Served with cheese, bacon, scallions, and diced tomatoes'
                                    },
                                    {
                                        title: 'Eggplant Parmesan',
                                        description: 'Stuffed with ricotta cheese'
                                    },
                                    {
                                        title: 'Braised, Grilled, or Roasted Chicken Thighs'
                                    },
                                    {
                                        title: 'Caprese Chicken'
                                    },
                                    {
                                        title: 'Chicken Marsala',
                                        description: 'With mushroom marsala wine sauce'
                                    },
                                    {
                                        title: 'Pork Tenderloin',
                                        description: 'With sauteed apples & onions, bacon, molasses & tomato chutney *Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Shrimp Scampi',
                                        description: 'With choice of vegetable, angel hair or linguine pasta, Italian or garlic bread'
                                    },
                                    {
                                        title: 'Filet Tips'
                                    },
                                    {
                                        title: 'Whole Filet Mignon',
                                        description: 'Carved and served with horseradish cream sauce & demi glace ($75 for carving attendant)'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With Remoulade or Dijon Cream Sauce'
                                    },
                                    {
                                        title: 'Chicken Piccata (capers, white wine and lemon sauce)',
                                        description: 'Served with Bowtie, Farfalle, or Fettucine'
                                    },
                                    {
                                        title: 'Starches',
                                        description: 'Mac and Cheese, Oven Roasted Rosemary Potatoes, Scalloped Potatoes, Mashed Potato Bar, Mashed Potatoes, Rice Pilaf, Grits, and Sweet Potato Souffle'
                                    },
                                    {
                                        title: 'Vegetables',
                                        description: 'Roasted Asparagus, Mixed Vegetables, Glazed Carrots, Roasted Brussel Sprouts, Green Peas, and Green Beans'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: 'staff',
                        title: 'Our Staff',
                        style: 'avatars',
                        staffMembers: [
                            {
                                name: 'Amber Taylor',
                                title: 'Event Coordinator',
                                image: '/images/amber.jpeg',
                            },
                            {
                                name: 'Tom Jackson',
                                title: 'Event Chef',
                                image: '/images/tom.jpg',
                            },
                            {
                                name: 'Alex Giglotti',
                                title: 'Event Chef',
                                image: '/images/alex.jpeg',
                            },
                        ]
                    },
                    {
                        type: 'contact',
                        from: 'catering',
                        style: 'simple',
                        email: 'goodtimescateredevents@gmail.com',
                        phone: '3039142002'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/cateringImages/1.jpg',
                            '/cateringImages/2.jpg',
                            '/cateringImages/3.jpg',
                            '/cateringImages/4.jpg',
                            '/cateringImages/5.jpg',
                            '/cateringImages/6.jpg',
                            '/cateringImages/7.jpg',
                            '/cateringImages/8.jpg',
                            '/cateringImages/9.jpg',
                            '/cateringImages/10.jpg',
                            '/cateringImages/11.jpg',
                            '/cateringImages/12.jpg',
                            '/cateringImages/13.jpg',
                            '/cateringImages/14.jpg',
                            '/cateringImages/15.jpg',
                            '/cateringImages/16.jpg',
                            '/cateringImages/17.jpg',
                        ]
                    }
                ]
            },
            {
                title: 'Food Menu',
                id: 'menus',
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                                type: 'external'
                            },
                            {
                                title: 'Catering',
                                path: '/catering',
                                type: 'external'
                            },
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'Food Menu',
                        orderLink: 'https://goodtimesbrewery.hrpos.heartland.us/menu',
                        categories: [
                            {
                                title: 'Munchies',
                                items: [
                                    {
                                        title: 'GT Onion Rings',
                                        price: '8'
                                    },
                                    {
                                        title: 'Truffle Fries or Tots',
                                        price: '7'
                                    },
                                    {
                                        title: 'Sweet Potato Fries',
                                        description: 'Served with a maple bacon dipping sauce',
                                        price: '7'
                                    },
                                    {
                                        title: 'Garlic Parmesan Fries',
                                        price: '7'
                                    },
                                    {
                                        title: 'Buffalo Tots',
                                        description: 'Diced tomatoes, chives, blue cheese crumbles, and hot sauce',
                                        price: '8'
                                    },
                                    {
                                        title: 'Beer Cheese Fries or Tots',
                                        description: 'Good Times beer cheese and bacon',
                                        price: '8'
                                    },
                                    {
                                        title: 'Plain Fries or Tots',
                                        price: '7'
                                    },
                                    {
                                        title: 'Giant Pretzel',
                                        description: 'Comes with Good Times beer cheese',
                                        price: '9'
                                    },
                                    {
                                        title: 'Pretzel Bites',
                                        description: 'Covered with Good Times beer cheese, bacon, and scallions',
                                        price: '6'
                                    },
                                    {
                                        title: 'Honey Jalapeno Muffins',
                                        description: '3 Jalapeno cornmeal muffins with cinnamon honey butter',
                                        price: '6'
                                    },
                                    {
                                        title: 'Lil Bites',
                                        description: '4 fried mac-n-cheese balls',
                                        price: '8'
                                    },
                                    {
                                        title: 'Good Times Tenders',
                                        description: '4 in house battered tenders served with Good Times BBQ sauce',
                                        price: '9'
                                    },
                                    {
                                        title: 'Good Times Nachos',
                                        description: 'Queso, Good Times beer cheese, pico, jalapenos, onions, black beans, and house made corn chips',
                                        price: '9'
                                    },
                                    {
                                        title: 'Wings (6)',
                                        description: 'Plain, lemon pepper, garlic parm, buffalo, Good Times BBQ sauce. Served with celery and either ranch or blue cheese dressing.',
                                        price: '11'
                                    },
                                    {
                                        title: 'Wings (12)',
                                        description: 'Plain, lemon pepper, garlic parm, buffalo, Good Times BBQ sauce. Served with celery and either ranch or blue cheese dressing.',
                                        price: '19'
                                    },
                                ]
                            },
                            {
                                title: 'Handhelds',
                                subtitle: 'Comes with side & pickle',
                                items: [
                                    {
                                        title: 'Candied Bacon Burger',
                                        description: 'Burger, cheddar cheese, candied bacon, bacon, lettuce, tomato, ketchup, and mayo',
                                        price: '13'
                                    },
                                    {
                                        title: 'Chicken Sandwich',
                                        description: 'Grilled chicken, sun-dried tomato pesto, mozzarella, and spinach',
                                        price: '13.50'
                                    },
                                    {
                                        title: 'Good Times Pulled Pork Hard Tacos',
                                        description: 'Pulled pork, tex-mex sauce, pico, lettuce, corn, jalapenos, black beans, and queso. Flour tortillas available.',
                                        price: '13.50'
                                    },
                                    {
                                        title: 'Blackend Tuna Sandwich',
                                        description: 'Seared Ahi tuna, asian slaw, and wasabi aioli',
                                        price: '16'
                                    },
                                    {
                                        title: 'Mac & Cheese Burger',
                                        description: 'Burger, fried mac & cheese, american cheese, bacon, crispy onions, and sriracha aioli',
                                        price: '14'
                                    },
                                    {
                                        title: 'Fish and Chips',
                                        description: 'Atlantic cod, Old Bay shoestring fries, and tarter sauce',
                                        price: '15'
                                    },
                                ]
                            },
                            {
                                title: 'Build Your Own Burger',
                                subtitle: 'Comes with lettuce, tomato, pickles, red onions, mayo, mustard, and ketchup. Choose from brioche bun or ciabatta',
                                items: [
                                    {
                                        title: 'Meat',
                                        description: 'Burger: $12.50, Chicken: $12, Pork: $13, Tuna: $16, Fried Cod: $12',
                                    },
                                    {
                                        title: 'Cheese',
                                        description: 'Swiss, pepper jack, mozzarella, cheddar, american, or blue cheese',
                                    },
                                    {
                                        title: 'Sauces',
                                        description: 'Queso, Good Times beer cheese, sriracha aioli, Good Times BBQ sauce, cilantro lime, maple aioli, tex-mex',
                                        price: '1.50'
                                    },
                                    {
                                        title: 'Toppings',
                                        description: 'Sauteed onions, cripsy onions, sauteed mushrooms, candied bacon, jalapenos, spinach, pico, and onion rings',
                                        price: '1'
                                    },
                                ]
                            },
                            {
                                title: 'Sides',
                                items: [
                                    {
                                        title: 'Regular Sides',
                                        description: 'Plain Fries, Tater Tots, or Onion Rings',
                                    },
                                    {
                                        title: 'Premium Sides',
                                        description: 'Truffle Fries, Garlic Parm Fries, Beer Cheese, Pimento Cheese Macaroni Salad, or Sweet Potato Fries',
                                        price: '2'
                                    },
                                ]
                            },
                            {
                                title: 'Salads',
                                items: [
                                    {
                                        title: 'Chopped South Western',
                                        description: 'Chopped romaine, black beans, tomatoes, red pepper, onions, cilantro lime dressing',
                                        price: '9'
                                    },
                                    {
                                        title: 'Add Chicken or Pork',
                                        price: '5'
                                    },
                                    {
                                        title: 'Add Tuna',
                                        price: '7'
                                    },
                                ]
                            },
                            {
                                title: 'Dessert',
                                items: [
                                    {
                                        title: 'Funnel Fries',
                                        description: 'Topped with powdered sugar and chocolate sauce',
                                        price: '7'
                                    },
                                    {
                                        title: 'Lemon Pound Cake',
                                        description: 'Thick sliced pound cake with lemon glazed icing',
                                        price: '7'
                                    },
                                    {
                                        title: 'Cheese Brownie Bars (2 Piece)',
                                        description: 'Topped with chociolate or caramel sauce',
                                        price: '5'
                                    },
                                    {
                                        title: 'Cheese Brownie Bars (4 Pieces)',
                                        description: 'Topped with chociolate or caramel sauce',
                                        price: '10'
                                    },
                                ]
                            },
                            {
                                title: 'Drinks',
                                items: [
                                    {
                                        title: 'Tea',
                                        price: '3'
                                    },
                                    {
                                        title: 'Coca Cola Products',
                                        price: '3'
                                    },
                                ]
                            },
                            {
                                title: 'Saturday Brunch',
                                description: '11am-2pm',
                                items: [
                                    {
                                        title: 'Breakfast Tots',
                                        description: 'Scrambled eggs, bacon, green onions, and Good Times beer cheese sauce',
                                        price: '8'
                                    },
                                    {
                                        title: 'Bacon, Egg, and Cheese Sandwich',
                                        description: 'Comes with a side of fries or tots',
                                        price: '10'
                                    },
                                    {
                                        title: 'French Toast Sticks',
                                        description: 'Sprinkled with powdered sugar. Comes with maple syrup and 3 slices of bacon',
                                        price: '8'
                                    },
                                    {
                                        title: 'Brunch Burger',
                                        description: 'Burger, fried egg, bacon, american cheese, crispy onions, and sriracha aioli. Comes with fries or tots.',
                                        price: '14'
                                    },
                                    {
                                        title: 'Rotating Quiche',
                                        description: 'Sliced quiche and spring mix with balsamic glaze',
                                        price: '10'
                                    },
                                    {
                                        title: 'Brunch Special',
                                        description: 'See bartender for details'
                                    },
                                    {
                                        title: 'Extras',
                                        description: '3 slices of bacon: $3.50, Fried Egg: $1.25',
                                        price: '14'
                                    },
                                ]
                            },
                            {
                                title: 'Saturday Brunch Drinks',
                                description: '11am-2pm',
                                items: [
                                    {
                                        title: 'Mimosa',
                                        description: 'Champagne and OJ',
                                        price: '8'
                                    },
                                    {
                                        title: 'Manmosa',
                                        description: 'Good Times beer and OJ',
                                        price: '8'
                                    },
                                    {
                                        title: 'Michelada',
                                        description: 'Good Times beer and bloody mary mix',
                                        price: '8'
                                    },
                                    {
                                        title: 'Bloody Mary',
                                        description: 'Vodka, bloody mary mix, skewer',
                                        price: '9'
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'Catering Menu',
                        categories: [
                            {
                                title: 'Trays',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Charcuterie Board',
                                        price: '250'
                                    },
                                    {
                                        title: 'Fruit Tray',
                                        price: '65'
                                    },
                                    {
                                        title: 'Vegetable Tray',
                                        price: '50'
                                    },
                                ]
                            },
                            {
                                title: 'Dips',
                                subtitle: 'Serves 25 people',
                                items: [
                                    {
                                        title: 'Fresh Made Hummus',
                                        description: 'Served with pita and veggies',
                                        price: '60'
                                    },
                                    {
                                        title: 'Warm Pimento Cheese',
                                        description: 'Served with pita chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Spinach and Artichoke Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                    {
                                        title: 'Buffalo Chicken Dip',
                                        description: 'Served with tortilla chips',
                                        price: '60'
                                    },
                                ]
                            },
                            {
                                title: `Hors D'oeuvres`,
                                subtitle: 'Three for $24 per person or four for $32 per person',
                                items: [
                                    {
                                        title: 'Antipasto skewers',
                                        description: 'Sun dried tomatoes, fresh mozzarella, and marinated artichoke hearts'
                                    },
                                    {
                                        title: 'Ham and Cheese Stuffed Mushrooms'
                                    },
                                    {
                                        title: 'Chicken Tenders'
                                    },
                                    {
                                        title: 'Wings',
                                        description: 'Served with two sauces and two dressings'
                                    },
                                    {
                                        title: 'Beef Sliders',
                                        description: 'Served with lettuce, tomato, pickles, mayo, mustard, ketchup, and cheese'
                                    },
                                    {
                                        title: 'Meatballs with marinara'
                                    },
                                    {
                                        title: 'Pigs in a Blanket'
                                    },
                                    {
                                        title: 'Crescent Wrapped Asparagus',
                                        description: 'Served with herbed cheese spread'
                                    },
                                    {
                                        title: 'Chicken Salad',
                                        description: 'Served with croissants'
                                    },
                                    {
                                        title: 'Assorted Mini Quiche'
                                    },
                                    {
                                        title: 'Prosciutto Wrapped Asparagus',
                                        description: 'With parmesan and balsamic glaze'
                                    },
                                ]
                            },
                            {
                                title: `More Options`,
                                subtitle: 'Three for $30 per person or four for $40 per person',
                                items: [
                                    {
                                        title: 'Poached Shrimp Cocktail',
                                        description: 'With remoulade, lemons, cocktail sauce, and butter'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With remoulade or dijon cream sauce'
                                    },
                                    {
                                        title: 'Seared Ahi Tuna',
                                        description: 'Sesame crusted or blackend'
                                    },
                                    {
                                        title: 'Bacon Wrapped Scallops',
                                    },
                                ]
                            },
                            {
                                title: `Homestyle Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Pulled Pork or BBQ Chicken',
                                        description: 'Served with toasted bun, baked beans, potato salad or coleslaw, and mac and cheese'
                                    },
                                    {
                                        title: 'Soft Taco Bar with Chicken or Beef',
                                        description: 'Comes with spanish rice, black beans, and churros, lettuce, tomato, cheese, sour cream and salsa provided.'
                                    },
                                    {
                                        title: 'Spaghetti and Meatballs',
                                        description: 'Served with marinara or alfredo, italian style green beans (prosciutto and red onions), or roasted seasonal vegetables, garden salad, and garlic bread'
                                    },
                                    {
                                        title: 'BYO Burger Bar (Beef Burgers or Grilled Chicken)',
                                        description: 'Served with brioche bun, coleslaw or potato salad, mac and cheese, and garden salad. Toppings include: lettuce, tomato, red onion, cheeses, pickles, mayo, mustard, and ketchup'
                                    },
                                    {
                                        title: 'Hamburger Steaks',
                                        description: 'With brown onion gravy, mashed potatoes, green beans, and rolls'
                                    },
                                ]
                            },
                            {
                                title: `Upscale Buffets`,
                                subtitle: '$25 per person. Choose one option',
                                items: [
                                    {
                                        title: 'Shrimp and Grits',
                                        description: 'Served with cheese, bacon, scallions, and diced tomatoes'
                                    },
                                    {
                                        title: 'Eggplant Parmesan',
                                        description: 'Stuffed with ricotta cheese'
                                    },
                                    {
                                        title: 'Braised, Grilled, or Roasted Chicken Thighs'
                                    },
                                    {
                                        title: 'Caprese Chicken'
                                    },
                                    {
                                        title: 'Chicken Marsala',
                                        description: 'With mushroom marsala wine sauce'
                                    },
                                    {
                                        title: 'Pork Tenderloin',
                                        description: 'With sauteed apples & onions, bacon, molasses & tomato chutney *Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Baked or grilled Salmon'
                                    },
                                    {
                                        title: 'Shrimp Scampi',
                                        description: 'With choice of vegetable, angel hair or linguine pasta, Italian or garlic bread'
                                    },
                                    {
                                        title: 'Filet Tips'
                                    },
                                    {
                                        title: 'Whole Filet Mignon',
                                        description: 'Carved and served with horseradish cream sauce & demi glace ($75 for carving attendant)'
                                    },
                                    {
                                        title: 'Crab Cakes',
                                        description: 'With Remoulade or Dijon Cream Sauce'
                                    },
                                    {
                                        title: 'Chicken Piccata (capers, white wine and lemon sauce)',
                                        description: 'Served with Bowtie, Farfalle, or Fettucine'
                                    },
                                    {
                                        title: 'Starches',
                                        description: 'Mac and Cheese, Oven Roasted Rosemary Potatoes, Scalloped Potatoes, Mashed Potato Bar, Mashed Potatoes, Rice Pilaf, Grits, and Sweet Potato Souffle'
                                    },
                                    {
                                        title: 'Vegetables',
                                        description: 'Roasted Asparagus, Mixed Vegetables, Glazed Carrots, Roasted Brussel Sprouts, Green Peas, and Green Beans'
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Beer Menu',
                id: 'beermenu',
                content: []
            },
            {
                title: 'Contact',
                id: 'contact',
                content: [
                    {
                        type: 'banner',
                        title: 'Events & Catering',
                        subtext: 'The Food And Space For Every Occasion',
                        noButtons: true,
                        image: '/images/catering.jpg',
                    },
                    {
                        type: 'contact',
                        from: 'catering',
                        style: 'simple',
                        email: 'goodtimescateredevents@gmail.com',
                        phone: '3039142002'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/cateringImages/1.jpg',
                            '/cateringImages/2.jpg',
                            '/cateringImages/3.jpg',
                            '/cateringImages/4.jpg',
                            '/cateringImages/5.jpg',
                            '/cateringImages/6.jpg',
                            '/cateringImages/7.jpg',
                            '/cateringImages/8.jpg',
                            '/cateringImages/9.jpg',
                            '/cateringImages/10.jpg',
                            '/cateringImages/11.jpg',
                            '/cateringImages/12.jpg',
                            '/cateringImages/13.jpg',
                            '/cateringImages/14.jpg',
                            '/cateringImages/15.jpg',
                            '/cateringImages/16.jpg',
                            '/cateringImages/17.jpg',
                        ]
                    }
                ]
            },
        ]
    }
}

export const millHouseData = {
    id: 'austin-123',
    path: '',
    url: 'localhost:3030',
    title: "The Mill House",
    logo: '/logo/mh-logo.png',
    favIcon: '/favicon.ico',
    companyName: 'Good Times Brewery',
    hours: [
        {
            days: 'Mon-Thu',
            hours: '11am-10pm',
        },
        {
            days: 'Fri-Sat',
            hours: '11am-11pm',
        },
        {
            days: 'Sunday',
            hours: '11am-10pm',
        },
    ],
    beers: [
        {
            id: '213bn0rubi213aqerh',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qergergqre',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213h453',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qeheqf',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qsfqer',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        },
        {
            id: '213bn0rubi213qerghq345',
            name: 'Dumpster Eagle',
            label: '/labels/label.jpg',
            description: "With Dumpster Eagle, you get a beer that's perfect for those who want to soar above the ordinary without the weight of a heavier brew. It's a tribute to freedom, resilience, and the unexpected beauty that can be found in the most unlikely of places. Whether you're perched on a rooftop bar or nestled in a backyard gathering, raise a glass to the rebel spirit and let your taste buds take flight. Cheers!"
        }
    ],
    theme: 'goodtimes',
    socials: {
        // youtube: "https://youtube.com",
        facebook: "https://www.facebook.com/TheMillHousePizza",
        // twitter: "https://twitter.com",
        // instagram: "https://instagram.com",
        google: "https://www.google.com/maps/place/The+Mill+House/@34.1898188,-82.1637555,15z/data=!4m5!3m4!1s0x0:0xb7ee51477d5e11e2!8m2!3d34.1898242!4d-82.163757"
    },
    nav: [
        {
            title: 'Home',
            id: 'home',
            index: 1,
            default: true
        },
        // {
        //     title: 'Our Beers',
        //     id: 'beers',
        //     index: 2,
        // },
        // {
        //     title: 'Catering',
        //     id: 'catering',
        //     index: 3
        // },
        // {
        //     title: 'Menus',
        //     index: 4,
        //     parent: true,
        //     children: [
        //         // {
        //         //     title: 'Beer',
        //         //     id: 'beermenu',
        //         //     index: 1,
        //         // },
        //         {
        //             title: 'Food',
        //             id: 'foodmenu',
        //             index: 2,
        //         },
        //     ]
        // },
        {
            title: 'Menu',
            id: 'foodmenu',
            index: 2,
        },
        // {
        //     title: 'Where To Buy',
        //     id: 'wtb',
        //     index: 5,
        // },
        {
            title: 'Contact',
            id: 'contact',
            index: 6,
        },
        {
            title: 'Good Times Brewing',
            id: 'goodtimes',
            url: 'http://goodtimes.beer',
            type: 'external',
            index: 6,
        },
        {
            title: 'Good Times Catering',
            id: 'catering',
            url: '/catering',
            type: 'external',
            index: 7
        },
    ],
    pages: {
        items: [
            {
                id: 'home',
                title: 'Home',
                default: true,
                content: [
                    {
                        type: 'banner',
                        image: '/images/pizza-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://themillhouse.hrpos.heartland.us/menu',
                                type: 'external'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        title: 'About Us',
                        content: `The foundations of The Mill House were formed in the back yard of a home in the Harris Mill village. Around 2006, a small gas fired brick oven was built that hosted many gatherings, one pizza at a time. Good beer, good pizza, and good company were good times to be had on the mill hill. In 2009 the market took a turn for the worse, and partners Gianpaolo Bonaca and Paul Bartolomeo decided it was time to get out of the then dying real estate market and instead lose sleep over building a restaurant. So they found a building in a quiet part of town, and began working on a plan to bring the same good times from Harris Mill to what would become The Mill House.
                        <br /><br />
                        Once we (barely) got open, The Mill House began to come alive on its own. As a strong team developed, we explored new things, expanded our horizons, and our community embraced us as we grew. Since starting as a small pizza and sandwich shop, we continually broaden our menu, cultivate a fine craft beer selection, host Sunday brunches and beer dinners, and keep the Good Times brews flowing from our on-site brewery. We support local farmers by using local produce and meats when possible, and our team takes pride in the quality of our work, the freshness of our ingredients, serving our patrons.
                        <br /><br />
                        But none of this would have been possible without our dedicated team and outstanding customers. We always love to hear your feedback to help us make this a better experience for you.`,
                        image: '/images/pizza-image.jpeg',
                        imageRight: true
                    },
                    // {
                    //     type: 'events',
                    //     events: {
                    //         items: [
                    //             {
                    //                 image: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/1694514/settings_images/w2lBYdzITWarofBWtdUA_contact_about.png',
                    //                 title: 'Jason Williams Polka Night',
                    //                 description: `Thought the man was just a bartender? You'd be wrong. He's an electrifying performer as well.`,
                    //                 dateTime: '2023-09-12T19:30:54-04:00',
                    //             },
                    //             {
                    //                 image: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/1694514/settings_images/w2lBYdzITWarofBWtdUA_contact_about.png',
                    //                 title: 'Jason Williams Polka Night',
                    //                 description: `Thought the man was just a bartender? You'd be wrong. He's an electrifying performer as well.`,
                    //                 dateTime: '2023-09-12T19:30:54-04:00',
                    //             },
                    //             {
                    //                 image: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/themes/1694514/settings_images/w2lBYdzITWarofBWtdUA_contact_about.png',
                    //                 title: 'Jason Williams Polka Night',
                    //                 description: `Thought the man was just a bartender? You'd be wrong. He's an electrifying performer as well.`,
                    //                 dateTime: '2023-09-12T19:30:54-04:00',
                    //             }
                    //         ]
                    //     }
                    // },
                    {
                        type: 'staff',
                        title: 'Our Staff',
                        staffMembers: [
                            {
                                name: 'Gianpaolo Bonaca',
                                title: 'Owner',
                                image: '/images/geep.jpeg',
                                content: `Gianpaolo (also answers to 'GP' and 'Geep', and has been called worse) came from Columbia SC to attend Lander University. In 2001 he graduated from the school of Business, and decided to stay involved with product development in the horticulture industry. This somehow seemed to lead to projects varying from building growth chambers, to electrical contracting, to real estate investing with his partner Paul, among other things. Gianpaolo’s late father had always wanted to build a brick oven, so during the era of doing 15 projects at a time he decided to build a small oven in the back yard of his mill house in the Harris Mill Village. After many moons of teardowns, rebuilds and profanity, the oven finally ran smoothly and provided perfect pies for many gatherings. When the economy took a down turn in 2009, Gianpaolo and partner Paul decided it was time to stop flipping houses and start slinging pies. With the strong support of his wife Riley and their 2 boys, their families, the city of Greenwood, a dedicated staff and even more dedicated patrons… Gianpaolo and Paul have enjoyed a gracious experience in hospitality since 2010.`
                            }
                        ]
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/mhImages/1.jpeg',
                            '/mhImages/2.jpeg',
                            '/mhImages/3.jpeg',
                            '/mhImages/4.jpeg',
                            '/mhImages/5.jpeg',
                            '/mhImages/6.jpeg',
                            '/mhImages/7.jpeg',
                            '/mhImages/8.jpeg',
                            '/mhImages/9.jpeg',
                            '/mhImages/10.jpeg',
                            '/mhImages/11.jpeg',
                            '/mhImages/12.jpeg',
                            '/mhImages/13.jpeg',
                            '/mhImages/14.jpeg',
                        ]
                    },
                    {
                        type: 'contact'
                    }
                ]
            },
            {
                title: 'Our Beers',
                id: 'beers',
                content: [
                    {
                        type: 'banner',
                        image: '/images/beer-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://themillhouse.hrpos.heartland.us/menu',
                                type: 'external'
                            }
                        ]
                    },
                    {
                        type: 'beerCards',
                    }
                ]
            },
            {
                title: 'Catering',
                id: 'catering',
                content: [
                    {
                        type: 'banner',
                        title: 'Good Times Catering',
                        subtext: 'Food for every occasion',
                        noButtons: true,
                        image: '/images/catering.jpeg',
                    },
                    {
                        type: 'text',
                        title: 'Catering',
                        content: `Just a year ago, the journey of Good Times Catering began, inspired by countless gatherings and shared meals among friends and loved ones. The essence of these gatherings? Good food, refreshing drinks, and unforgettable company. Recognizing the joy and satisfaction these events brought, founders Gianpaolo Bonaca and Paul Bartolomeo took a leap of faith. Diving into the world of catering, they channeled their passion for culinary arts and delivering joy, creating what we now know as Good Times Catering.
                        <br /><br />
                        In the brief span since its inception, Good Times Catering has witnessed an incredible evolution. What started as a humble service catering simple yet delicious meals has expanded its offerings, introducing diverse menu options tailored to an array of tastes and preferences. The commitment to quality has remained steadfast, with a strong emphasis on sourcing local ingredients to ensure freshness and support the community. This dedication to excellence is reflected in every dish we serve, every event we cater, and the glowing reviews from our esteemed clientele.
                        <br /><br />
                        Central to our growth and success is our dedicated team, who pour their heart and soul into every task, ensuring the delivery of an unmatched catering experience. Our valued customers have been instrumental in our journey, guiding us with their feedback, entrusting us with their events, and sharing in the good times. We are always eager to hear from you, continually striving to elevate your experience with us.`,
                        image: '/images/catering.jpeg',
                        imageRight: true
                    },
                    {
                        type: 'staff',
                        title: 'Our Staff',
                        style: 'avatars',
                        staffMembers: [
                            {
                                name: 'Gianpaolo Bonaca',
                                title: 'Owner',
                                image: '/images/geep.jpeg',
                                content: `Gianpaolo (also answers to 'GP' and 'Geep', and has been called worse) came from Columbia SC to attend Lander University. In 2001 he graduated from the school of Business, and decided to stay involved with product development in the horticulture industry. This somehow seemed to lead to projects varying from building growth chambers, to electrical contracting, to real estate investing with his partner Paul, among other things. Gianpaolo’s late father had always wanted to build a brick oven, so during the era of doing 15 projects at a time he decided to build a small oven in the back yard of his mill house in the Harris Mill Village. After many moons of teardowns, rebuilds and profanity, the oven finally ran smoothly and provided perfect pies for many gatherings. When the economy took a down turn in 2009, Gianpaolo and partner Paul decided it was time to stop flipping houses and start slinging pies. With the strong support of his wife Riley and their 2 boys, their families, the city of Greenwood, a dedicated staff and even more dedicated patrons… Gianpaolo and Paul have enjoyed a gracious experience in hospitality since 2010.`
                            },
                            {
                                name: 'Paul Bartolomeo',
                                title: 'Owner',
                                image: '/images/paul.jpeg',
                                content: `Paul is a Washington DC area native who had spent time in the area until he permanently relocated to Hodges in 2004. Sticking with what he did best in DC, Paul started rehabilitating properties around Greenwood. With new challenges in a new market, Paul teamed up with a long time friend and SC native Gianpaolo, and together they decided to purchase properties to then improve and market the properties for sale or rent. The market downturn in 2009 forced a change in business plans, so Paul and his partner decided to take a leap of faith into the restaurant business. With a shell of a building in a quiet part of town they put their resources together to bring a new dining experience to Greenwood. Paul continues to have as much time on the floor as he can, though he is well known for his quiet personality (not really). Paul and Gianpaolo enjoy spending time with our community and our patrons… you can't miss Paul!`
                            },
                            {
                                name: 'Gianpaolo Bonaca',
                                title: 'Owner',
                                image: '/images/geep.jpeg',
                                content: `Gianpaolo (also answers to 'GP' and 'Geep', and has been called worse) came from Columbia SC to attend Lander University. In 2001 he graduated from the school of Business, and decided to stay involved with product development in the horticulture industry. This somehow seemed to lead to projects varying from building growth chambers, to electrical contracting, to real estate investing with his partner Paul, among other things. Gianpaolo’s late father had always wanted to build a brick oven, so during the era of doing 15 projects at a time he decided to build a small oven in the back yard of his mill house in the Harris Mill Village. After many moons of teardowns, rebuilds and profanity, the oven finally ran smoothly and provided perfect pies for many gatherings. When the economy took a down turn in 2009, Gianpaolo and partner Paul decided it was time to stop flipping houses and start slinging pies. With the strong support of his wife Riley and their 2 boys, their families, the city of Greenwood, a dedicated staff and even more dedicated patrons… Gianpaolo and Paul have enjoyed a gracious experience in hospitality since 2010.`
                            },
                            {
                                name: 'Paul Bartolomeo',
                                title: 'Owner',
                                image: '/images/paul.jpeg',
                                content: `Paul is a Washington DC area native who had spent time in the area until he permanently relocated to Hodges in 2004. Sticking with what he did best in DC, Paul started rehabilitating properties around Greenwood. With new challenges in a new market, Paul teamed up with a long time friend and SC native Gianpaolo, and together they decided to purchase properties to then improve and market the properties for sale or rent. The market downturn in 2009 forced a change in business plans, so Paul and his partner decided to take a leap of faith into the restaurant business. With a shell of a building in a quiet part of town they put their resources together to bring a new dining experience to Greenwood. Paul continues to have as much time on the floor as he can, though he is well known for his quiet personality (not really). Paul and Gianpaolo enjoy spending time with our community and our patrons… you can't miss Paul!`
                            }
                        ]
                    },
                    {
                        type: 'contact',
                        from: 'catering',
                        style: 'simple',
                        email: 'goodtimescateredevents@gmail.com',
                        phone: '3039142002'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/gtImages/1.jpeg',
                            '/gtImages/2.jpeg',
                            '/gtImages/3.jpeg',
                            '/gtImages/4.jpeg',
                            '/gtImages/5.jpeg',
                            '/gtImages/6.jpeg',
                            '/gtImages/7.jpeg',
                            '/gtImages/8.jpeg',
                            '/gtImages/9.jpeg',
                            '/gtImages/10.jpeg',
                            '/gtImages/11.jpeg',
                            '/gtImages/12.jpeg',
                            '/gtImages/13.jpeg',
                            '/gtImages/14.jpeg',
                            '/gtImages/15.jpeg',
                            '/gtImages/16.jpeg',
                            '/gtImages/17.jpeg',
                            '/gtImages/18.jpeg',
                            '/gtImages/19.jpeg',
                            '/gtImages/20.jpeg',
                            '/gtImages/21.jpeg',
                            '/gtImages/22.jpeg',
                            '/gtImages/23.jpeg',
                            '/gtImages/24.jpeg',
                        ]
                    }
                ]
            },
            {
                title: 'Food Menu',
                id: 'foodmenu',
                content: [
                    {
                        type: 'banner',
                        image: '/images/pizza-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://themillhouse.hrpos.heartland.us/menu',
                                type: 'external'
                            }
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'Create Your Own Pizza or Calzone ($10)',
                        orderLink: 'https://themillhouse.hrpos.heartland.us/menu',
                        categories: [
                            {
                                title: `Sauces`,
                                items: [
                                    {
                                        title: 'Red Sauce',
                                        description: 'Our tomato sauce recipe with fresh basil, garlic & spices'
                                    },
                                    {
                                        title: 'White Sauce',
                                        description: 'Our ricotta & cream recipe with garlic, fresh basil, red pepper, & spices'
                                    },
                                    {
                                        title: 'Pink Sauce',
                                        description: 'A natural marriage of the two, a smooth balance of Red and White sauce'
                                    },
                                    {
                                        title: 'Evoo Sauce',
                                        description: 'Extra virgin olive oil, garlic & rosemary'
                                    },
                                    {
                                        title: 'Pesto Cream Sauce',
                                        description: 'Our house white sauce with basil pesto'
                                    },
                                    {
                                        title: 'Mojo Sauce',
                                        description: 'Our unique blend of Caribbean herbs and spices in red pepper aioli'
                                    },
                                    {
                                        title: 'Great Goodness Sauce',
                                        description: 'A rustic tomato sauce'
                                    },
                                ]
                            },
                            {
                                title: `Toppings`,
                                subtitle: 'Priced per topping',
                                items: [
                                    {
                                        title: 'Add Your Own Toppings',
                                        description: 'Anchovies, Artichoke hearts, bacon, banana pepper, beef, bell pepper, black olive, broccoli, capers, extra cheese, feta, fresh basil, fresh mozzarella, garlic, goat cheese, Gouda, green olive, Italian sausage, jalapeño, Kalamata olive, lettuce, mango, meatballs, mushroom, pepperoni, pickle, pineapple, pine nuts, prosciutto, red onion, ricotta (cheese), roasted red pepper, salami, shrimp, smoked salmon, spinach, sun-dried tomato, tomato',
                                        price: '2'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'menu',
                        title: 'Menu',
                        orderLink: 'https://themillhouse.hrpos.heartland.us/menu',
                        categories: [
                            {
                                title: 'Specialty Pizzas',
                                subtitle: 'We offer GLUTEN-FREE and MULTI-GRAIN crust upon request for an additional charge.',
                                items: [
                                    {
                                        title: `House Calzone`,
                                        description: 'Ricotta and pizza cheese',
                                        price: '11',
                                        chip: 'Great Goodness Sauce'
                                    },
                                    {
                                        title: `Pauly's Preferred`,
                                        description: 'Cheese, pepperoni, beef, Italian sausage, bell pepper, mushroom, red onion',
                                        price: '14',
                                        chip: 'Red Sauce'
                                    },
                                    {
                                        title: `The Titus`,
                                        description: 'Cheese, pepperoni, prosciutto, beef, Italian sausage, bacon',
                                        price: '15',
                                        chip: 'Red Sauce'
                                    },
                                    {
                                        title: `BBQ Chicken`,
                                        description: 'Cheese, chicken, bacon, red onion, smoked Gouda',
                                        price: '14',
                                        chip: 'Red & BBQ Sauce'
                                    },
                                    {
                                        title: `Cheese Burger`,
                                        description: 'Cheese, beef, red onion, bacon, cheddar cheese, mustard, diced pickle',
                                        price: '14.50',
                                        chip: 'Red Sauce'
                                    },
                                    {
                                        title: `Fungus Amungus`,
                                        description: 'Cheese, button mushroom, yellow oyster mushroom, shiitake mushroom, crimini mushroom, portabella mushroom, blue cheese, truffle oil',
                                        price: '15',
                                        chip: 'White Sauce'
                                    },
                                    {
                                        title: `The Super Nova`,
                                        description: 'Cheese, smoked salmon, capers, red onion, Gouda, grape tomato',
                                        price: '15',
                                        chip: 'White Sauce'
                                    },
                                    {
                                        title: `Margherita`,
                                        description: 'Pine nuts, fresh mozzarella, fresh basil',
                                        price: '12.50',
                                        chip: 'Great Goodness Sauce'
                                    },
                                    {
                                        title: `Veg-Out`,
                                        description: 'Cheese, grape tomato, red onion, bell pepper, mushroom, jalapeño, black olive',
                                        price: '13',
                                        chip: 'Red Sauce'
                                    },
                                    {
                                        title: `Buffalo Blue`,
                                        description: 'Cheese, chicken, blue cheese',
                                        price: '13.50',
                                        chip: 'Mojo Sauce'
                                    },
                                    {
                                        title: `Flyin' Hawaiian`,
                                        description: 'Cheese, pineapple, red onion, jalapeño, prosciutto',
                                        price: '13.50',
                                        chip: 'Red Sauce'
                                    },
                                    {
                                        title: `Mediterranean`,
                                        description: 'Cheese, spinach, banana pepper, Kalamata olive, black olive, grape tomato, red onion, feta',
                                        price: '14.50',
                                        chip: 'Evoo Sauce'
                                    },
                                    {
                                        title: `The Harris Mill`,
                                        description: 'Cheese, sun-dried tomato, chicken, spinach, mushroom',
                                        price: '14.50',
                                        chip: 'White Sauce'
                                    },
                                    {
                                        title: `Jack Sparrow`,
                                        description: 'Cheese, chicken, mango, bacon',
                                        price: '14.50',
                                        chip: 'Mojo Sauce'
                                    },
                                    {
                                        title: `The Bruce Wayne`,
                                        description: 'Fresh mozz, prosciutto, roasted red peppers, basil, balsamic glaze',
                                        price: '14.50',
                                        chip: 'Great Goodness Sauce'
                                    },
                                    {
                                        title: `B.L.T.`,
                                        description: 'Cheese, bacon bits, lettuce, mayonnaise',
                                        price: '14.50',
                                        chip: 'Cheese & Bacon Base'
                                    },
                                ]
                            },
                            {
                                title: 'Subs, Sandwiches, and Wraps',
                                subtitle: 'Served with a kosher pickle and one side',
                                items: [
                                    {
                                        title: 'Meatball Sub',
                                        description: 'A toasted hoagie roll stuffed with meatballs, rustic marinara, and mozzarella cheese.',
                                        price: '12'
                                    },
                                    {
                                        title: 'King of Clubs',
                                        description: 'This is our signature club. Turkey, ham, roast beef, lettuce, tomato & mayo nestled between a pizza crust baked with cheese and bacon.',
                                        price: '13'
                                    },
                                    {
                                        title: 'Chicken Parmesan Sub',
                                        description: 'A toasted hoagie roll with cheese and Parmesan chicken. Served with a side of rustic marinara',
                                        price: '12'
                                    },
                                    {
                                        title: 'The Corleone',
                                        description: 'A toasted hoagie roll, salami, pepperoni, ham, cheese, roma tomato, onion and green leaf. Served with a side of balsamic vinaigrette.',
                                        price: '12'
                                    },
                                    {
                                        title: 'The Mill House Original',
                                        description: 'Tender marinated pulled roast beef, sautéed onion, roasted red pepper and melted mozzarella. Served with au jus.',
                                        price: '12'
                                    },
                                    {
                                        title: 'The Kitson Wrap',
                                        description: 'Spinach, tomato, cucumber, roasted red pepper,feta, red onion, MH hummus & olive tapenade',
                                        price: '11'
                                    }
                                ]
                            },
                            {
                                title: 'Pasta',
                                subtitle: 'Served with a house salad & garlic bread',
                                items: [
                                    {
                                        title: 'Spaghetti & Meatballs',
                                        description: 'Our house specialty meatballs and rustic marinara on a hearty mound of spaghetti.',
                                        price: '15'
                                    },
                                    {
                                        title: 'Chicken Parmesan',
                                        description: 'Parmesan breaded chicken filet covered with rustic marinara and melted mozzarella served over spaghetti.',
                                        price: '15'
                                    },
                                    {
                                        title: 'Fettuccine Alfredo',
                                        description: 'Fettuccine noodles cooked in our homemade Alfredo sauce, sautéed portabella mushroom and broccoli.',
                                        price: '13'
                                    }
                                ]
                            },
                            {
                                title: 'Salads',
                                subtitle: 'House made dressings: Mill House ranch • blue cheese Italian • Balsamic vinaigrette • Caesar • honey mustard • 1000 island',
                                items: [
                                    {
                                        title: 'House Salad',
                                        description: 'Fresh red and green leaf lettuce, tomato, red onion, cucumber, carrots and bell pepper',
                                        price: '5',
                                        chip: 'GLUTEN FREE'
                                    },
                                    {
                                        title: 'The Mill House Cobb Salad',
                                        description: 'Romaine lettuce, egg, roasted chicken, bacon, bleu cheese crumbles, tomato and red onion',
                                        price: '15',
                                        chip: 'GLUTEN FREE'
                                    },
                                    {
                                        title: 'Antipasto Salad',
                                        description: 'Fresh red and green leaf lettuce, grape tomato, onion, bell pepper, cucumber, ham, salami, pepperoni, black olive, banana pepper and your choice of feta or fresh mozzarella cheese',
                                        price: '15',
                                        chip: 'GLUTEN FREE'
                                    },
                                    {
                                        title: 'Chicken Parmesan Salad',
                                        description: 'Fresh red and green leaf lettuce, grape tomato, onion, bell pepper, cucumber, Parmesan chicken and shredded Parmesan cheese',
                                        price: '15',
                                    },
                                    {
                                        title: 'Caesar Salad',
                                        description: 'Romaine lettuce tossed with Caesar dressing and topped with toasted croutons and shaved Parmesan cheese',
                                        price: '6',
                                    },
                                ]
                            },
                            {
                                title: 'Sides',
                                items: [
                                    {
                                        title: 'Potato Salad',
                                        description: '',
                                        price: '3.50'
                                    },
                                    {
                                        title: 'Mill House Pasta Salad',
                                        description: '',
                                        price: '3.50'
                                    },
                                    {
                                        title: 'Potato Chips',
                                        description: '',
                                        price: '3.50'
                                    }
                                ]
                            },

                            {
                                title: 'To Share',
                                items: [
                                    {
                                        title: 'Cheese Bread',
                                        description: 'Served with marinara & garlic butter',
                                        price: '10'
                                    },
                                    {
                                        title: 'Hummus & Pita Chips',
                                        description: 'Served with celery and bell pepper',
                                        price: '10.50'
                                    },
                                    {
                                        title: 'Stuffed Mushrooms',
                                        description: `Button cap 'shrooms filled with artichokes, ricotta and Gouda cheeses, served in alfredo sauce with toast`,
                                        price: '11'
                                    },
                                    {
                                        title: 'Hot Wings',
                                        description: `A half dozen hot wings fried up just the way you like them. Sauces: Imperial ‧ Major ‧ Mild ‧ Buffalo ‧ Killa Bee. Get a dozen for $19`,
                                        price: '11'
                                    },
                                    {
                                        title: 'Fried Mozzarella Bites',
                                        description: `A generous portion of our house made fried mozzarella bites served with a side of marinara sauce`,
                                        price: '10'
                                    },
                                    {
                                        title: 'Spinach and Artichoke Dip',
                                        description: `Savory house made dip served with fried pita chips`,
                                        price: '10.50'
                                    }
                                ]
                            },
                            {
                                title: 'Desserts',
                                items: [
                                    {
                                        title: 'Chocolate Chip Pizza',
                                        description: `Chocolate chipped cookie dough hearth baked on our pizza base`,
                                        price: '10'
                                    },
                                    {
                                        title: 'Hearth Streusel Bread',
                                        description: `Streusel topping & cream cheese icing on hearth bread - great for an appetizer or dessert`,
                                        price: '10'
                                    }
                                ]
                            },
                            {
                                title: 'Drinks',
                                subtitle: '',
                                items: [
                                    {
                                        title: 'Fountain Drinks',
                                        description: 'Sweet Tea, Unsweet Tea, Coke®, Diet Coke®, Sprite®, Mello Yellow®, Pibb Xtra®, Minute Maid® Lemonade',
                                        // price: '2.50'
                                    },
                                    {
                                        title: 'Fresh Brewed Coffee',
                                        description: '',
                                        // price: '2.50'
                                    },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                title: 'Beer Menu',
                id: 'beermenu',
                content: []
            },
            {
                title: 'Contact',
                id: 'contact',
                content: [
                    {
                        type: 'banner',
                        image: '/images/pizza-banner.jpeg',
                        buttons: [
                            {
                                title: 'Order Online',
                                path: 'https://themillhouse.hrpos.heartland.us/menu',
                                type: 'external'
                            }
                        ]
                    },
                    {
                        type: 'contact'
                    },
                    {
                        type: 'imageGallery',
                        images: [
                            '/gtImages/1.jpeg',
                            '/gtImages/2.jpeg',
                            '/gtImages/3.jpeg',
                            '/gtImages/4.jpeg',
                            '/gtImages/5.jpeg',
                            '/gtImages/6.jpeg',
                            '/gtImages/7.jpeg',
                            '/gtImages/8.jpeg',
                            '/gtImages/9.jpeg',
                            '/gtImages/10.jpeg',
                            '/gtImages/11.jpeg',
                            '/gtImages/12.jpeg',
                            '/gtImages/13.jpeg',
                            '/gtImages/14.jpeg',
                            '/gtImages/15.jpeg',
                            '/gtImages/16.jpeg',
                            '/gtImages/17.jpeg',
                            '/gtImages/18.jpeg',
                            '/gtImages/19.jpeg',
                            '/gtImages/20.jpeg',
                            '/gtImages/21.jpeg',
                            '/gtImages/22.jpeg',
                            '/gtImages/23.jpeg',
                            '/gtImages/24.jpeg',
                        ]
                    }
                ]
            },
        ]
    }
}

