import { Box, Button, Container, CssBaseline, Grid, IconButton, Stack, ThemeProvider, Typography, createTheme, styled, useMediaQuery } from "@mui/material";
import ContactSection from "./components/Contact";
import Footer from "./footer/Footer";
import { FacebookOutlined, Google } from "@mui/icons-material";
import { useNavigate } from "react-router";

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                },
                sizeLarge: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                },
                sizeSmall: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                },
                input: {
                    color: 'black',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#737373',
                    '&.Mui-focused': {
                        color: 'black',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                //   root: {
                //     backgroundColor: 'black',
                //     color: 'white',
                //     borderRadius: 0, // No border radius
                //     padding: '18px 24px', // Extra padding for large size
                //     border: '2px solid white', // White two-pixel thick outline
                //     '&:hover': {
                //         backgroundColor: 'white',
                //         color: 'black',
                //         borderRadius: 0, // No border radius
                //         padding: '18px 24px', // Extra padding for large size
                //         border: '2px solid black', // White two-pixel thick outline
                //     },
                //   },
                sizeLarge: {
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 0, // No border radius
                    padding: '18px 24px', // Extra padding for large size
                    border: '2px solid white', // White two-pixel thick outline
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 0, // No border radius
                        border: '2px solid black', // White two-pixel thick outline
                    },
                },
                sizeMedium: {
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 0, // No border radius
                    padding: '12px 18px', // Extra padding for large size
                    border: '2px solid white', // White two-pixel thick outline
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 0, // No border radius
                        border: '2px solid black', // White two-pixel thick outline
                    },
                },
                sizeSmall: {
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 0, // No border radius
                    padding: '12px 16px', // Extra padding for large size
                    border: '2px solid white', // White two-pixel thick outline
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 0, // No border radius
                        border: '2px solid black', // White two-pixel thick outline
                    },
                }
                // You can customize other button variants here if needed
            },
        },
    },
});

/* ---------------------------- Styled Components --------------------------- */

const StyledImage = styled('img')``

export default function LandingPage() {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'))
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} spacing={0}>
                {isLg ?
                    <Grid item xs={12} style={{ display: 'flex', flexGrow: 1 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={6} sx={{ '&:hover': { backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/pizza-banner.jpeg")' }, display: 'flex', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/pizza-banner.jpeg")', backgroundRepeat: 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <Box py={10} px={3} sx={{ m: 'auto' }}>
                                    <center>
                                        <StyledImage src="/logo/mh-logo.png" sx={{ maxHeight: 400, maxWidth: 400 }} />
                                        <br />
                                        <Button onClick={() => { navigate('/millhouse')}} size="large" variant='contained' sx={{ mt: 5 }}>
                                            Learn More
                                        </Button>
                                    </center>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ '&:hover': { backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/beer-banner.jpeg")' }, display: 'flex', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/beer-banner.jpeg")', backgroundRepeat: 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <Box py={10} px={3} sx={{ m: 'auto' }}>
                                    <center>
                                        <StyledImage src="/logo/gt-logo.png" sx={{ maxHeight: 400, maxWidth: 400 }} />
                                        <br />
                                        <Button onClick={() => { navigate('/goodtimes')}} size="large" variant='contained' sx={{ mt: 5 }}>
                                            Learn More
                                        </Button>
                                    </center>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid item xs={12} sx={{ '&:hover': { backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/pizza-banner.jpeg")' }, display: 'flex', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/pizza-banner.jpeg")', backgroundRepeat: 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <Box py={10} px={3}>
                                <center>
                                    <StyledImage src="/logo/mh-logo.png" sx={{ maxHeight: 400, maxWidth: '100%' }} />
                                    <br />
                                    <Button onClick={() => { navigate('/millhouse')}} size="large" variant='contained' sx={{ mt: 5 }}>
                                        Learn More
                                    </Button>
                                </center>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ '&:hover': { backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/beer-banner.jpeg")' }, display: 'flex', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/beer-banner.jpeg")', backgroundRepeat: 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <Box py={10} px={3}>
                                <center>
                                    <StyledImage src="/logo/gt-logo.png" sx={{ maxHeight: 400, maxWidth: '100%' }} />
                                    <br />
                                    <Button onClick={() => { navigate('/goodtimes')}} size="large" variant='contained' sx={{ mt: 5 }}>
                                        Learn More
                                    </Button>
                                </center>
                            </Box>
                        </Grid>
                    </>
                }
                <Grid item xs={12} sx={{ '&:hover': { backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/catering.jpg")' }, backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/images/catering.jpg")', backgroundRepeat: 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <Box py={10} px={3}>
                        <center>
                            <Typography variant="h2" fontWeight={800}>
                                Good Times Catering
                            </Typography>
                            <br />
                            <Button onClick={() => { navigate('/goodtimes/catering')}} size="large" variant='contained' sx={{ mt: 0 }}>
                                Learn More
                            </Button>
                        </center>
                    </Box>
                </Grid>
            </Grid>
            <Container maxWidth sx={{ backgroundColor: theme => theme.palette.common.white, color: theme => theme.palette.common.black, py: 8 }} disableGutters>
                <ContactSection index={1} />
                <Stack direction='row' spacing={2} justifyContent='center' py={3}>
                    <IconButton color='white' size='large' onClick={() => { window.open('https://facebook.com', '_blank') }}>
                        <FacebookOutlined />
                    </IconButton>
                    <IconButton color='white' size='large' onClick={() => { window.open('https://google.com', '_blank') }}>
                        <Google />
                    </IconButton>
                </Stack>
                <Typography variant='h5' fontSize={28} textAlign='center'>
                    237 MAXWELL AVE GREENWOOD, SC 29646 &#x2022; 864-323-0321
                </Typography>
            </Container>
        </ThemeProvider >
    )

}