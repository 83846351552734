import PropTypes from 'prop-types';
import WebsiteContainer from "../utilities/Container";
import RenderedBeerCan from './BeerCan';
import { Button, Card, Chip, Divider, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router';




export default function BeerCards({ beers, index }) {
    const { pageId, beerId } = useParams();
    const navigation = useNavigate();
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const theme = useTheme();

    /* ---------------------------- Styled Components --------------------------- */

    const StyledCard = styled(Card)({
        backgroundColor: 'transparent',
        borderRadius: 16,
        padding: 30,
        borderStyle: 'dashed',
        borderWidth: 3,
        borderColor: theme.palette.grey[300]
    })

    if (beerId) {
        return (
            <WebsiteContainer index={index} maxWidthContainerSx={{ maxWidth: '95vw' }}>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={12} md={4}>
                        <StyledCard elevation={0}>
                            <RenderedBeerCan height={isMd ? 400 : 400} label={beers.find(obj => obj.id = beerId).label} />
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant='h3' color='black' fontWeight={800}>
                            {beers.find(obj => obj.id = beerId).name} <Chip color='success' label='AVAILABLE AT GOOD TIMES' />
                        </Typography>
                        <Typography mt={3} variant='h6' color='black' fontWeight={800}>
                            {beers.find(obj => obj.id = beerId).description}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography
                variant='h4'
                fontWeight={800}
                textAlign='center'
                sx={{
                    mt: 8,
                    mb: 4
                }}
            >
                Reseller Availability
            </Typography>
            <Divider sx={{backgroundColor: theme => theme.palette.grey[500]}} />
            </WebsiteContainer>
        )
    }

    return (
        // <WebsiteContainer index={index} maxWidthContainerSx={{maxWidth: '95vw'}}>
        //     {beers.map(beer =>
        //     <RenderedBeerCan label={beer.label} />
        //     )}
        // </WebsiteContainer>
        <WebsiteContainer index={index} maxWidthContainerSx={{ maxWidth: '95vw' }}>
            <Typography
                variant='h4'
                fontWeight={800}
                textAlign={isMd ? 'left' : 'center'}
                sx={{
                    textDecoration: 'underline',
                    textDecorationThickness: 1,
                    textDecorationColor: theme => theme.palette.grey[400],
                    textDecorationStyle: 'solid',
                    textUnderlineOffset: 13,
                    mb: 4
                }}
            >
                Beers
            </Typography>
            <Grid container spacing={3}>

                {beers.map((beer, index) =>
                    <Grid key={index} item xs={12} md={6} lg={4} color='black'>
                        <StyledCard elevation={0}>
                            <Stack>
                                <RenderedBeerCan label={beer.label} />
                                <Typography variant='body1' fontSize={24} fontWeight={800} textAlign='center' color='black'>
                                    {beer.name}
                                </Typography>
                                <Typography
                                    variant='body1'
                                    fontSize={17}
                                    fontWeight={500}
                                    mt={1}
                                    textAlign='center'
                                    color='black'
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        lineHeight: '1.4',  // Adjust this if necessary
                                        maxHeight: '4.2em'  // 3 times the line-height
                                    }}
                                >
                                    {beer.description}
                                </Typography>
                                <Button onClick={() => { navigation(`/${pageId}/${beer.id}`) }} size='medium' sx={{ mt: 3 }}>
                                    Learn More
                                </Button>
                            </Stack>
                        </StyledCard>
                    </Grid>
                )}

            </Grid>
        </WebsiteContainer>
    )
}

BeerCards.propTypes = {
    beers: PropTypes.array,
    index: PropTypes.number
}