import { Container, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';



WebsiteContainer.propTypes = {
    props: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.object
}

export default function WebsiteContainer(props) {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('isLg'))
    const isEven = props.index % 2 === 0;

    return (
        <Container maxWidth sx={{ ...(props.maxWidthContainerSx && {...props.maxWidthContainerSx}), backgroundColor: theme => isEven ? theme.palette.grey[200] : theme.palette.grey[0], }}>
            <Container maxWidth={props.maxWidth || 'lg'} disableGutters={isLg ? props.disableGutters || true : false} {...props} sx={{ py: 6, color: theme => theme.palette.common.black, ...props.sx }}>
                {props.children}
            </Container>
        </Container>
    )
}